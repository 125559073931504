import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import authURL from "../../configuration/config";
import { AiOutlineDelete } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import { BiMessageSquareAdd } from "react-icons/bi";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../Elements/Loading";
import { nonImageURL, productImageURL } from "../../configuration/domainURL";
import DeleteModal from "../Elements/DeleteModal";

export default function OneProduct() {
  const [data, setData] = useState({});
  const [image, setImage] = useState([]);
  const [delShow, setDelShow] = useState(false);
  const [delItem, setDelItem] = useState("");
  const [imageShow, setImageShow] = useState(false);
  const { id } = useParams();
  const [file, setFile] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [loading, setLoading] = useState(false);

  var striptags = require("striptags");
  // var allImages = [];

  useEffect(() => {
    const getSingleProduct = () => {
      setLoading(true);
      authURL
        .get("/products/" + id)
        .then((response) => {
          console.log(response);
          setLoading(false);
          setData(response.data.data.product);
          setImage(response.data.data.product.gallery);
          handleSingleProductCategory(response.data.data.product.category_id);
          handleSingleProductBrand(response.data.data.product.brand_id);
          // const gallery = response.data.data.product.gallery;
          // gallery.forEach((image) => {
          //   allImages = [data.image, image];
          // });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getSingleProduct();
  }, [id]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDelShow(false);
    setImageShow(false);
  };

  const handleDelete = (item) => {
    // setDelID(ID);
    setDelItem(item);
    setDelShow(true);
  };

  const handleRemove = () => {
    const ImageAfterDelete = data.gallery.filter((data) => data === delItem);
    const formData = new FormData();
    formData.append("product_id", id);
    formData.append("image_name", ImageAfterDelete);
    authURL
      .post("/product/gallery/remove-image", formData)
      .then((response) => {
        setImage(response.data.data.product.gallery);
        setDelShow(false);
        toast.success(response.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddImageShow = () => {
    setImageShow(true);
  };

  const uploadSingleFile = (e) => {
    const selectedFiles = e.target.files;
    // Create an object for each selected file
    const imageObjects = Object.keys(selectedFiles).map((key) => {
      return selectedFiles[key];
    });

    // Update the images state with the image objects
    setFile([...file, ...imageObjects]);
  };

  const deleteFile = (e) => {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  };

  const handleAddImage = () => {
    const formData = new FormData();
    formData.append("product_id", id);
    file.forEach((file) => {
      formData.append("image[]", file);
    });
    authURL
      .post("/product/gallery/add-image", formData)
      .then((response) => {
        console.log(response);
        setImage(response.data.data.product.gallery);
        setData(response.data.data.product);
        setImageShow(false);
        setFile([]);
        toast.success(response.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSingleProductCategory = (categoryID) => {
    authURL
      .get("/categories/" + categoryID)
      .then((response) => {
        setCategoryData(response.data.data.category);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSingleProductBrand = (brandID) => {
    authURL
      .get("/brands/" + brandID)
      .then((response) => {
        setBrandData(response.data.data.brand);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 stretch-card">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Product Details</h6>
                <div className="mb-2 mt-2 ">
                  <p className="heading">Title & Description</p>
                  <hr />
                  <div className="row">
                    <div className="col-sm-12 col-md-4 d-lg-none d-xs-block mb-3 ">
                      {data.image === "no-image.png" ? (
                        <div className="card img_card">
                          <img src={nonImageURL} alt="singleProduct_image" />
                        </div>
                      ) : (
                        <div className="card primaryimg_card">
                          <img
                            src={data.image_url}
                            alt="product_image"
                            className="px-3"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-8">
                      <p className="product_name mb-1">{data.name}</p>
                      <p className="product_description">
                        {data.description == null ? (
                          <p>N/A</p>
                        ) : (
                          <p>{striptags(data.description)}</p>
                        )}
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-4 d-lg-block d-none">
                      {data.image === "no-image.png" ? (
                        <div className="card img_card">
                          <img src={nonImageURL} alt="singleProduct_image" />
                        </div>
                      ) : (
                        <div className="card primaryimg_card">
                          <img
                            src={productImageURL + data.image}
                            alt="product_image"
                            className="px-3"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <p className="heading">Images</p>
                  <hr />
                  <div className="row">
                    {image.length === 0 ? (
                      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3 card img_card my-2">
                        <img src={nonImageURL} alt="extra_product_image" />
                      </div>
                    ) : (
                      <>
                        {image.map((item, index) => {
                          return (
                            <div
                              className="col-sm-12 col-md-4 col-lg-4 col-xl-3 my-2"
                              key={index}
                            >
                              <div
                                className="card img_card "
                                style={{
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={productImageURL + item}
                                  className="px-3"
                                  key={index}
                                  alt="extra_product_image"
                                />
                                <AiOutlineDelete
                                  style={{
                                    position: "absolute",
                                    fontSize: "18px",
                                    color: "red",
                                    cursor: "pointer",
                                    top: 7,
                                    right: 15,
                                  }}
                                  onClick={() => handleDelete(item)}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3 my-2">
                      <div className="card img_card card-dotted ">
                        <BiMessageSquareAdd
                          className="image_icon"
                          onClick={handleAddImageShow}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <p className="heading">Specifications </p>
                  <hr />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex">
                        <div className="specfication_list">
                          <li className="mb-2">Price:</li>
                          <li className="mb-2">Cost:</li>
                          <li className="mb-2">Quantity:</li>
                        </div>
                        <div className="specfication_list ms-3">
                          <li className="mb-2">Rs {data.price}</li>
                          <li className="mb-2">Rs {data.cost}</li>
                          <li className="mb-2">{data.quantity}</li>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex">
                        <div className="specfication_list">
                          <li className="mb-2">Category:</li>
                          <li className="mb-2">Brand:</li>
                        </div>
                        <div className="specfication_list ms-3">
                          <li className="mb-2">
                            {" "}
                            {data.category_id == null ? (
                              <p>N/A</p>
                            ) : (
                              <p>{categoryData.name}</p>
                            )}
                          </li>
                          <li className="mb-2">
                            {" "}
                            {data.brand_id == null ? (
                              <p>N/A</p>
                            ) : (
                              <p>{brandData.name}</p>
                            )}
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <p className="heading">Description</p>
                  <hr />
                  <p>
                    {" "}
                    {data.description == null ? (
                      <p>N/A</p>
                    ) : (
                      <p>{striptags(data.description)}</p>
                    )}
                  </p>
                </div>
                <div className="mb-3">
                  <p className="heading">Additional Description</p>
                  <hr />
                  <p>
                    {" "}
                    {data.additional_information == null ? (
                      <p>N/A</p>
                    ) : (
                      <p>{striptags(data.additional_information)}</p>
                    )}
                  </p>
                </div>
                <div className="mb-3">
                  <p className="heading">Shipping Returns</p>
                  <hr />
                  <p>
                    {" "}
                    {data.shipping_returns == null ? (
                      <p>N/A</p>
                    ) : (
                      <p>{striptags(data.shipping_returns)}</p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/*Delete Modal */}
      <DeleteModal
        modalTitle={"Product"}
        delShow={delShow}
        handleClose={handleClose}
        handleRemove={handleRemove}
      />

      {/* Add multiple image */}
      <Modal
        show={imageShow}
        // fullscreen
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="forms-sample">
            <div className="row">
              <div className="col-sm-12 mb-3">
                <label htmlFor="name" className="form-label">
                  Multiple Images
                </label>
                <div className="multiple-images mb-3">
                  <div className="dropify-messsage">
                    <span className="file-icon">
                      <p className="mt-6" style={{ color: "#20212469" }}>
                        Drag and drop a file here or click
                      </p>
                    </span>
                  </div>
                  <input
                    type="file"
                    disabled={file.length === 5}
                    className="form-control"
                    onChange={uploadSingleFile}
                    accept=".jpeg, .png, .jpg, .gif, .svg, .webp"
                    multiple
                  />
                </div>
                <div className="form-group preview">
                  <div className="row">
                    {file.length > 0 &&
                      file.map((item, index) => {
                        return (
                          <div className="col-sm-2 mb-2" key={index}>
                            <div
                              className="card "
                              // style={{ height: "100%" }}
                            >
                              <img
                                src={URL.createObjectURL(item)}
                                alt=""
                                // width={174}
                                // height={150}
                                className="mb-3"
                                style={{
                                  // objectFit: "cover",
                                  // resizeMode: "contain",
                                  // flex: 1,
                                  aspectRatio: 1,
                                }}
                              />
                              <div className="row">
                                <div className="col-sm-6"></div>
                                <div className="col-sm-6">
                                  <AiOutlineDelete
                                    type="button"
                                    onClick={() => deleteFile(index)}
                                    className="mb-2 float-end mx-3"
                                    style={{
                                      fontSize: "20px",
                                      color: "red",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleAddImage}
          >
            Add Images
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
}
