import React from "react";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-2 border-top small">
        <p className="text-muted mb-1 mb-md-0">
          Copyright © {currentYear}{" "}
          <a href="/" target="_blank">
            SQEM
          </a>
          .
        </p>
      </footer>
    </div>
  );
}
