import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoutes = () => {
  // let auth = { token: sessionStorage.getItem("loginToken") };
  let auth = { token: Cookies.get("token",null) }; 
  return auth.token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
