import React, { useState, useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../Elements/Loading";
import URL from "../../../configuration/config";

export default function FooterSection4() {
  const [addElement, setAddElement] = useState(false);
  const [footerElements, setFooterElements] = useState({});
  const [data, setData] = useState([]);
  const name = "Footer Section #4";
  const [footerSection4, setFooterSection4] = useState({
    title: "",
    footerLink: [],
  });
  const footerLinkSection = {};
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getFooterElements = () => {
      setLoading(true);
      URL.get("/footer-payload-system/all")
        .then((response) => {
          setLoading(false);
          const footerData = response.data.data.footer_payload_system;
          setFooterElements(footerData);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getFooterElements();
  }, []);

  useEffect(() => {
    const getFooterElementsByName = () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      URL.post("/footer-payload-system/get", formData)
        .then((response) => {
          if (response.status === 200) {
            setData(response.data.data.footer_payload_system);
            setFooterSection4(
              JSON.parse(response.data.data.footer_payload_system.payload)
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          const { status } = error.response;
          if (status === 404) {
            setData(null);
          }
        });
    };

    for (let i = 0; i < footerElements.length; i++) {
      const item = footerElements[i];
      if (item.name === name) {
        getFooterElementsByName();
      }
    }
  }, [footerElements, setData]);

  const handleChange = (e, index) => {
    const updatedLinks = [...footerSection4.footerLink];
    const updatedLinkSection = {
      ...updatedLinks[index].footerLinkSection, // Get the current section data for the current index
      [e.target.name]: e.target.value,
    };
    const updatedLink = {
      footerLinkSection: updatedLinkSection,
    };
    updatedLinks[index] = updatedLink; // Update the current array element with the updated link object
    setFooterSection4({
      ...footerSection4,
      footerLink: updatedLinks,
    });
  };

  const handleAddFirst = (e) => {
    setAddElement(true);
    const newFooterLink = [
      ...footerSection4.footerLink,
      {
        footerLinkSection,
      },
    ];
    setFooterSection4({ ...footerSection4, footerLink: newFooterLink });
  };
  const handleTitleChange = (event) => {
    const newTitle = event.target.value;
    setFooterSection4({ ...footerSection4, title: newTitle });
  };

  const handleAddFooterSection = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("payload", JSON.stringify(footerSection4));

    URL.post("/footer-payload-system/add-new", formData)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.data.footer_payload_system);
          setLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditFooterSection = (footerData) => {
    setLoading(true);
    const footerUpdate = [...footerElements];
    const index = footerElements.findIndex((data) => data.id === footerData.id);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("payload", JSON.stringify(footerSection4));
    formData.append("_method", "PUT");
    if (index >= 0) {
      footerUpdate[index].name = name;
      footerUpdate[index].payload = JSON.stringify(footerSection4);
      URL.post("/footer-payload-system/update", formData)
        .then((response) => {
          setData(response.data.data.footer_payload_system);
          setLoading(false);
          toast.success(response.data.message);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  return (
    <div>
      {data.length === 0 ? (
        <div>
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              {name}
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse "
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {loading ? (
                <Loading />
              ) : (
                <div>
                  <div className="mb-2">
                    <label htmlFor="name" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Title"
                      required
                      value={footerSection4.title}
                      onChange={handleTitleChange}
                    />
                  </div>
                  <div>
                    <div
                      title="Add Footer Elements"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      onClick={handleAddFirst}
                      className="text-end"
                    >
                      <AiOutlinePlusCircle
                        size={18}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    {addElement && (
                      <>
                        {footerSection4.footerLink.map((item, i) => {
                          const sectionNameFieldName = `sectionName`;
                          const sectionLinkFieldName = `sectionLink`;
                          return (
                            <div className="row" key={i}>
                              <div className="col-md-6 mb-2">
                                <label htmlFor="name" className="form-label">
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="title"
                                  placeholder="Name"
                                  name={sectionNameFieldName}
                                  required
                                  value={footerLinkSection.sectionName}
                                  onChange={(e) => handleChange(e, i)}
                                />
                              </div>
                              <div className="col-md-6 mb-2">
                                <label htmlFor="name" className="form-label">
                                  Link
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="title"
                                  placeholder="Link"
                                  name={sectionLinkFieldName}
                                  required
                                  value={footerLinkSection.sectionLink}
                                  onChange={(e) => handleChange(e, i)}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                  <div className="text-end mt-3">
                    <button
                      type="button"
                      className="btn btn-primary px-5"
                      onClick={handleAddFooterSection}
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              {name}
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse "
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {loading ? (
                <Loading />
              ) : (
                <div>
                  <div className="mb-2">
                    <label htmlFor="name" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Title"
                      required
                      value={footerSection4.title}
                      onChange={handleTitleChange}
                    />
                  </div>
                  <div>
                    <div
                      title="Add Footer Elements"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      onClick={handleAddFirst}
                      className="text-end"
                    >
                      <AiOutlinePlusCircle
                        size={18}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    {/* {addElement && (
                  <> */}
                    {footerSection4.footerLink.map((item, i) => {
                      const sectionNameFieldName = `sectionName`;
                      const sectionLinkFieldName = `sectionLink`;
                      return (
                        <div className="row" key={i}>
                          <div className="col-md-6 mb-2">
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Name"
                              required
                              name={sectionNameFieldName}
                              value={item.footerLinkSection.sectionName}
                              onChange={(e) => handleChange(e, i)}
                            />
                          </div>
                          <div className="col-md-6 mb-2">
                            <label htmlFor="name" className="form-label">
                              Link
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="link"
                              placeholder="Link"
                              required
                              name={sectionLinkFieldName}
                              value={item.footerLinkSection.sectionLink}
                              onChange={(e) => handleChange(e, i)}
                            />
                          </div>
                        </div>
                      );
                    })}
                    {/* </>
                )} */}
                  </div>
                  <div className="text-end mt-3">
                    <button
                      type="button"
                      className="btn btn-primary px-5"
                      onClick={() => handleEditFooterSection(data)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
