import React from "react";
import CustomerDetails from "../components/CustomerList/CustomerDetails";

const Users = () => {
  return (
    <div className="page-content">
      <CustomerDetails />
    </div>
  );
};

export default Users;
