import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import JoditEditor from "jodit-react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FiAlertTriangle } from "react-icons/fi";
import { IconPicker } from "react-fa-icon-picker";
import { ToastContainer, toast } from "react-toastify";
import URL from "../../configuration/config";
import Loading from "../Elements/Loading";

export default function FeatureSection({ name2 }) {
  const [delShow, setDelShow] = useState(false);
  const editorRef = useRef(null);
  const [addElement, setAddElement] = useState(false);
  const [featureSection, setFeatureSection] = useState({
    featureItem: [],
  });
  const [loading, setLoading] = useState(false);
  const [featureData, setFeatureData] = useState([]);
  const [featureElements, setFeatureElements] = useState([]);

  const featureItemSection = {};

  useEffect(() => {
    const getFeatureSection = () => {
      setLoading(true);
      URL.get("/cms-payload-system/all")
        .then((response) => {
          setLoading(false);
          const data = response.data.data.cms_payload_system;
          setFeatureElements(data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    getFeatureSection();
  }, []);

  useEffect(() => {
    const getFeatureElementsByName = () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name2);
      URL.post("/cms-payload-system/get", formData)
        .then((response) => {
          if (response.status === 200) {
            setFeatureData(response.data.data.cms_payload_system);
            setFeatureSection(
              JSON.parse(response.data.data.cms_payload_system.payload)
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    for (let i = 0; i < featureElements.length; i++) {
      const item = featureElements[i];
      if (item.name === name2) {
        getFeatureElementsByName();
      }
    }
  }, [featureElements, name2]);

  const handleDeleteAboutSection = () => {
    setDelShow(true);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDelShow(false);
  };

  const handleAddFirst = (e) => {
    setAddElement(true);
    const newLink = [
      ...featureSection.featureItem,
      {
        featureItemSection,
      },
    ];
    setFeatureSection({ ...featureSection, featureItem: newLink });
  };

  const handleChange = (section, e, index) => {
    // Add a conditional check to ensure that e is an event object with a target property
    const updatedLinks = [...featureSection.featureItem];
    const updatedLinkSection = {
      ...updatedLinks[index].featureItemSection,
      [section]: e,
    };
    updatedLinks[index].featureItemSection = updatedLinkSection; // Update the current link section object in the array
    setFeatureSection({
      ...featureSection,
      featureItem: updatedLinks,
    });
  };

  const handleAddFeatureSection = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name2);
    formData.append("payload", JSON.stringify(featureSection));

    URL.post("/cms-payload-system/add-new", formData)
      .then((response) => {
        if (response.status === 200) {
          setFeatureData(response.data.data.cms_payload_system);
          setLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditFeatureSection = (featureData) => {
    setLoading(true);
    const aboutUpdate = [...featureElements];
    const index = featureElements.findIndex(
      (data) => data.id === featureData.id
    );
    const formData = new FormData();
    formData.append("name", name2);
    formData.append("payload", JSON.stringify(featureSection));
    formData.append("_method", "PUT");
    if (index >= 0) {
      aboutUpdate[index].name = name2;
      aboutUpdate[index].payload = JSON.stringify(featureSection);
      URL.post("/cms-payload-system/update", formData)
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  const handleRemove = () => {
    // featureSection.pop();
  };
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {featureData.length === 0 ? (
            <div>
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <h6>Our Feature</h6>
                </div>
                <div onClick={handleAddFirst}>
                  <AiOutlinePlusCircle
                    size={24}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              {/* Feature Section */}
              {addElement && (
                <div className="row justify-content-center mb-5 gx-2 gy-5">
                  {featureSection.featureItem.map((item, i) => {
                    const titleField = `title`;
                    const iconField = `icon`;
                    const descriptionField = `description`;
                    return (
                      <div className="col-lg-4 col-sm-6" key={i}>
                        <div className="card p-3">
                          <div className="d-flex justify-content-end">
                            {/* <MdOutlineDeleteOutline
                              size={24}
                              color="red"
                              className="mx-2"
                              style={{ cursor: "pointer" }}
                              onClick={handleDeleteAboutSection}
                            /> */}
                          </div>
                          {/* <img
              src="/assets/images/pictures/plugin.png"
              alt="icon"
              className="mb-3"
            /> */}

                          <div className="row">
                            <div className="col-md-4 mb-2">
                              <label htmlFor="name" className="form-label">
                                Icon
                              </label>
                              <div className="icon-picker-textfield">
                                <IconPicker
                                  value={item.featureItemSection.icon}
                                  onChange={(e) =>
                                    handleChange(iconField, e, i)
                                  }
                                  name={iconField}
                                  // onChange={(v) => setValue(v)}
                                />
                              </div>
                            </div>
                            <div className="col-md-8 mb-3">
                              <label htmlFor="name" className="form-label">
                                Title
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Title"
                                name={titleField}
                                value={item.featureItemSection.title}
                                onChange={(e) =>
                                  handleChange(titleField, e.target.value, i)
                                }
                              />
                            </div>
                            {/* End .icon-box-title */}
                            <div className="col-md-12 mb-3">
                              <label htmlFor="name" className="form-label">
                                Description
                              </label>
                              <JoditEditor
                                ref={editorRef}
                                value={item.featureItemSection.description}
                                onBlur={(e) =>
                                  handleChange(descriptionField, e, i)
                                }
                              />
                              {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      name={descriptionField}
                      value={item.featureItemSection.description}
                      onChange={(e) =>
                        handleChange(descriptionField, e.target.value, i)
                      }
                    /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleAddFeatureSection}
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <h6>Our Feature</h6>
                </div>
                <div onClick={handleAddFirst}>
                  <AiOutlinePlusCircle
                    size={24}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              {/* Feature Section */}
              <div className="row justify-content-center mb-5 gx-2 gy-5">
                {featureSection.featureItem.map((item, i) => {
                  const titleField = `title`;
                  const iconField = `icon`;
                  const descriptionField = `description`;
                  return (
                    <div className="col-lg-4 col-sm-6" key={i}>
                      <div className="card p-3">
                        <div className="d-flex justify-content-end">
                          <MdOutlineDeleteOutline
                            size={24}
                            color="red"
                            className="mx-2"
                            style={{ cursor: "pointer" }}
                            onClick={handleDeleteAboutSection}
                          />
                        </div>

                        <div className="row">
                          <div className="col-md-4 mb-2">
                            <label htmlFor="name" className="form-label">
                              Icon
                            </label>
                            <div className="icon-picker-textfield">
                              <IconPicker
                                color="blue"
                                value={item.featureItemSection.icon}
                                onChange={(e) => handleChange(iconField, e, i)}
                                name={iconField}
                                // onChange={(v) => setValue(v)}
                              />
                            </div>
                          </div>
                          <div className="col-md-8 mb-3">
                            <label htmlFor="name" className="form-label">
                              Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Title"
                              name={titleField}
                              value={item.featureItemSection.title}
                              onChange={(e) =>
                                handleChange(titleField, e.target.value, i)
                              }
                            />
                          </div>
                          {/* End .icon-box-title */}
                          <div className="col-md-12 mb-3">
                            <label htmlFor="name" className="form-label">
                              Description
                            </label>
                            <JoditEditor
                              ref={editorRef}
                              value={item.featureItemSection.description}
                              onBlur={(e) =>
                                handleChange(descriptionField, e, i)
                              }
                            />
                            {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      name={descriptionField}
                      value={item.featureItemSection.description}
                      onChange={(e) =>
                        handleChange(descriptionField, e.target.value, i)
                      }
                    /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleEditFeatureSection(featureData)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {/*Delete Feature Section  */}
      <Modal
        show={delShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Feature Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="icontext">
            <FiAlertTriangle className="alert_icon py-2" />
            <h4>Do you really want to delete it?</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleRemove}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
}
