import React from "react";

export default function Card({ title, amount, icon }) {
  return (
    <div className="card dashboard-card">
      <div className="card-body">
        {/* <div className="d-flex justify-content-between align-items-baseline mb-2"></div> */}
        <div className="d-flex justify-content-around flex-column flex-xl-row align-items-center ">
          <div className="icon-border d-sm-flex justify-content-sm-center d-md-block">
            <div className="dashboard-icon">{icon}</div>
          </div>
          <div className="d-flex flex-column  align-items-center align-items-start  justify-content-lg-center mt-3 dashboard-content text-sm-center">
            <h6 className="card-title mb-2 ">{title}</h6>
            <h3 className="mb-2">{amount}</h3>
            <div className="d-flex align-items-baseline"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
