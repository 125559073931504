import React from "react";
import ContactInfo from "../components/Contact/ContactInfo";

export default function Contact() {
  return (
    <div className="page-content">
      <ContactInfo />
    </div>
  );
}
