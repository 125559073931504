import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import URL from "../../configuration/config";
import Loading from "../Elements/Loading";

export default function SingleContactPage() {
  const [contactInfo, setContactInfo] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getSingleContactInfo = () => {
      setLoading(true);
      URL.get("/contacts/" + id)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setLoading(false);
            setContactInfo(response.data.data.category);
          }
        })
        .catch((error) => {});
    };
    getSingleContactInfo();
  }, [id]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="row ">
                <h6 className="card-title">Contact Information</h6>
              </div>
              <div className="row">
                <div className="table-responsive pt-3">
                  <table className="table table-bordered mb-3">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>{contactInfo.name}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{contactInfo.email}</td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td>{contactInfo.phone}</td>
                      </tr>
                      <tr>
                        <td>Subject</td>
                        <td>{contactInfo.subject}</td>
                      </tr>
                      <tr>
                        <td>Message</td>
                        <td>{contactInfo.message}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
