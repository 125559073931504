import React from "react";
import System from "../components/Settings/System";

export default function SystemSettings() {
  return (
    <div className="page-content">
      <System />
    </div>
  );
}
