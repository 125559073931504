import Cookies from "js-cookie";
import React, { createContext, useContext, useState, useEffect } from "react";
import URL from "../configuration/config";

const ThemeContext = createContext();
const LoginThemeContext = createContext();
const LoginDetailsThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const useLoginTheme = () => {
  return useContext(LoginThemeContext);
};

export const useLoginDetailsTheme = () => {
  return useContext(LoginDetailsThemeContext);
};

export const ThemeProvider = ({ children }) => {
  const [brandTotal, setBrandTotal] = useState(0);
  const [categoryTotal, setCategoryTotal] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [login, setLogin] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const getCategoryData = () => {
      URL.get("/categories", {
        params: {
          limit: categoryTotal,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            setCategoryData(result.data.data.categories);
            setCategoryTotal(result.data.data.totalRows);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const getAllBrands = () => {
      URL.get("/brands", {
        params: {
          limit: brandTotal,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            var data = result.data.data.brands;
            setBrandData(data);
            setBrandTotal(result.data.data.totalRows);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const getProfileDetails = () => {
      URL.get("/profile/data")
        .then((response) => {
          setEmail(response.data.data.user.email);
          setName(response.data.data.user.name);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const token = Cookies.get("token");
    //-- Check if contains token
    if (token !== null && token !== undefined) {
      getAllBrands();
      getCategoryData();
      getProfileDetails();
    }
  }, [categoryTotal, brandTotal]);

  return (
    <div>
      <ThemeContext.Provider
        value={{
          brandTotal,
          categoryTotal,
          categoryData,
          brandData,
        }}
      >
        <LoginThemeContext.Provider value={{ login, setLogin }}>
          <LoginDetailsThemeContext.Provider value={{ name, email, setName }}>
            {children}
          </LoginDetailsThemeContext.Provider>
        </LoginThemeContext.Provider>
      </ThemeContext.Provider>
    </div>
  );
};
