import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import URL from "../../configuration/config";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../Elements/Loading";

export default function TermsCondition() {
  const editorRef = useRef(null);
  const [answer, setAnswer] = useState("");
  const page = "Terms-and-Conditions";
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getContent();
  }, []);

  const Validation = () => {
    let error = "";
    let isValid = true;

    if (!answer) {
      error = "This field is required";
      isValid = false;
    }

    setError(error);
    return isValid;
  };

  const getContent = () => {
    setLoading(true);
    URL.get("/page/" + page)
      .then((response) => {
        console.log(response);
        const data = response.data.data.page_info;
        if (data === null) {
          setAnswer(response.data.data.page_info);
        } else {
          setAnswer(response.data.data.page_info.page_data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAdd = () => {
    const isValid = Validation();
    if (isValid) {
      const formData = new FormData();
      formData.append("page_name", page);
      formData.append("detail", answer);
      URL.post("/page/" + page + "/update", formData)
        .then((response) => {
          console.log(response);
          setAnswer(response.data.data.page_info.page_data);
          toast.success("Updated successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <div>
                    <h6 className="card-title">Terms and Condition</h6>
                    <label className=" mb-3 form-label">
                      Add Content to terms and Condition
                    </label>
                    <JoditEditor
                      ref={editorRef}
                      value={answer}
                      onBlur={(content) => setAnswer(content)}
                    />
                    <div className="my-3" style={{ color: "red" }}>
                      {error}
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary float-end mt-3"
                      onClick={handleAdd}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
