import React from "react";
import OrderInvoice from "../components/Orders/OrderInvoice";

export default function OrderDetail() {
  return (
    <div className="page-content">
      <OrderInvoice />
    </div>
  );
}
