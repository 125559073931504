import React from "react";
import Refund from "../components/Refund/Refund";

const RefundPolicy = () => {
  return (
    <div className="page-content">
      <Refund />
    </div>
  );
};

export default RefundPolicy;
