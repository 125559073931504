// react imports
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import URL from "../../configuration/config"

// third party components
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import Cookies from "js-cookie"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// custom components
import { useLoginTheme } from "../../context/ThemeProvider"
import Loading from "../Elements/Loading"

export default function LoginDetails() {
  const navigate = useNavigate()
  // states
  const [email, setEmail] = useState("")
  const [values, setValues] = useState({
    password: "",
    showPassword: false
  })
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [loading, setLoading] = useState(false)
  const { login, setLogin } = useLoginTheme()

  // useEffect(() => {
  //   // if (!login) {
  //   //   Cookies.set("token", null);
  //   // }
  // }, [login])

  //error message validation
  function Validate() {
    let emailError = " "
    let passwordError = " "

    let isValid = true
    if (!email) {
      emailError = "Please enter your email"
      isValid = false
    }
    if (!values.password) {
      passwordError = "Please enter your password"
      isValid = false
    } else if (values.password.length < 5) {
      passwordError = "Password must be at least 6 characters"
      isValid = false
    }
    setEmailError(emailError)
    setPasswordError(passwordError)
    return isValid
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    })
  }

  //URL for logging in
  const handleLogin = async (e) => {
    e.preventDefault()
    const Valid = Validate()
    if (Valid) {
      const formData = new FormData()
      formData.append("email", email)
      formData.append("password", values.password)
      try {
        setLoading(true)
        const response = await URL.post("/login", formData)
        // Check the response status code
        // if (response.status === 200) {
        //   const token = response.data.data.token
        //   // Set the new token in the "Authorization" header for future requests
        //   URL.defaults.headers.common["Authorization"] = `Bearer ${token}`
        //   setLogin(true)
        //   localStorage.setItem("name", response.data.data.name)
        //   const expires = new Date()
        //   expires.setDate(expires.getDate() + 1) // Expires in 1 day
        //   Cookies.set("token", token, {
        //     expires: expires
        //   })
        //   toast.success("Login successful", {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light"
        //   })
        //   setLoading(false)
        //   navigate("/")
        //   window.location.reload(true)
        // } else {
        //   // Handle unexpected status codes
        //   toast.error("An error occurred", {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light"
        //   })
        //   setLoading(false)
        // }
        const token = response.data.data.token
        // Set the new token in the "Authorization" header for future requests
        URL.defaults.headers.common["Authorization"] = `Bearer ${token}`
        setLogin(true)
        localStorage.setItem("name", response.data.data.name)
        const expires = new Date()
        expires.setDate(expires.getDate() + 1) // Expires in 1 days
        Cookies.set("token", token, {
          expires: expires
        })
        toast.success("Login successful", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        })
        setLoading(false)
        navigate("/")
        window.location.reload(true)
      } catch (error) {
        setLoading(false)
        console.log(error)
        // if (error.response) {
        //   const { status } = error.response
        //   if (status === 404) {
        //     toast.error("User not found", {
        //       position: "top-right",
        //       autoClose: 5000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //       theme: "light"
        //     })
        //   } else if (status === 401) {
        //     toast.error("Invalid credentials", {
        //       position: "top-right",
        //       autoClose: 5000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //       theme: "light"
        //     })
        //   } else {
        //     toast.error("An error occurred", {
        //       position: "top-right",
        //       autoClose: 5000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //       theme: "light"
        //     })
        //   }
        // } else {
        //   // Handle network or other errors
        //   toast.error("Network error", {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light"
        //   })
        // }
        toast.error("An error occured", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        })
      }

      // URL.post("/login", formData)
      //   .then((result) => {
      //     if (result.status === 200) {
      //       // const token = JSON.stringify(result.data.data.token);
      //       const token = result.data.data.token
      //       // Set the new token in the "Authorization" header for future requests
      //       URL.defaults.headers.common["Authorization"] = `Bearer ${token}`
      //       setLogin(true)
      //       localStorage.setItem("name", result.data.data.name)
      //       const expires = new Date()
      //       expires.setDate(expires.getDate() + 1) // Expires in 1 days
      //       Cookies.set("token", token, {
      //         expires: expires
      //       })
      //       setLoading(false)
      //       navigate("/")
      //       window.location.reload(true)
      //     }
      //     toast.success("Login successful", {
      //       position: "top-right",
      //       autoClose: 5000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: "light"
      //     })
      //   })
      //   .catch((err) => {
      //     setLoading(false)
      //     if (err.response) {
      //       const { status } = err.response
      //       if (status === 404) {
      //         setLoading(false)
      //         // Cookies.set("token", null);
      //       }
      //     }
      //   })
    }
  }

  return (
    <div>
      {/* {loading ? (
        <Loading />
      ) : (

      )} */}
      <div className="main-wrapper">
        <div
          className="page-wrapper full-page"
          style={{
            backgroundImage: 'url("/assets/images/pictures/login-3.jpg")',
            color: "#000",
            height: "100vh",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
        >
          <div className="page-content d-flex align-items-center justify-content-center">
            <div className="row w-100 mx-0 auth-page">
              <div className="col-md-8 col-xl-6 mx-auto">
                <div className="card">
                  <div className="row">
                    <div className="col-md-4 pe-md-0">
                      <div className="auth-side-wrapper"></div>
                    </div>
                    <div className="col-md-8 ps-md-0">
                      <div className="auth-form-wrapper px-4 py-4">
                        <img
                          src="/assets/images/full_logo.png"
                          alt="logo"
                          height={100}
                          className="mb-4"
                        />
                        <h5 className="text-muted fw-normal mb-4">
                          Log in to your account to continue.
                        </h5>
                        <form className="forms-sample">
                          <div className="mb-2">
                            <label htmlFor="userEmail" className="form-label">
                              Email address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="userEmail"
                              placeholder="Email"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="mb-3" style={{ color: "red" }}>
                            {emailError}
                          </div>
                          <div className="mb-2">
                            <label
                              htmlFor="userPassword"
                              className="form-label"
                            >
                              Password
                            </label>
                            <div className="textfield_icon">
                              <input
                                type={values.showPassword ? "text" : "password"}
                                value={values.password}
                                className="form-control"
                                id="userPassword"
                                autoComplete="current-password"
                                placeholder="Password"
                                required
                                onChange={handleChange("password")}
                              />
                              <span
                                onClick={handleClickShowPassword}
                                className="eye-icon"
                              >
                                {values.showPassword ? (
                                  <AiFillEyeInvisible />
                                ) : (
                                  <AiFillEye />
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="mb-3" style={{ color: "red" }}>
                            {passwordError}
                          </div>
                          <div className="mb-3 login-btn">
                            <button
                              className="btn btn-primary text-white"
                              onClick={handleLogin}
                            >
                              Login
                            </button>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-check mb-3">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="authCheck"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="authCheck"
                                >
                                  Remember me
                                </label>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <p className="text-end register-link">
                                Don't have an account?{" "}
                                <a href="/register">Register here</a>
                              </p>
                            </div>
                            {/* <div className="col-md-6">
                                <a
                                  href="/"
                                  className="d-block text-muted text-center float-end"
                                >
                                  Forgot Password?
                                </a>
                              </div> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
