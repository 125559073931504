import React from "react";
import OneProduct from "../components/Product/OneProduct";

export default function SingleProduct() {
  return (
    <div className="page-content">
      <OneProduct />
    </div>
  );
}
