import React from "react";
import AboutSection from "./AboutSection";
import FeatureSection from "./FeatureSection";
import PartnerSection from "./PartnerSection";
import StatesSection from "./StatesSection";

export default function AboutForm() {
  const name1 = "About";
  const name2 = "Feature Section";
  const name3 = "States";
  const name4 = "Partners";

  return (
    <div>
      <div className="row">
        <div className="col-md-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">About</h2>
              <ul
                className="nav nav-tabs nav-tabs-line"
                id="lineTab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="about-line-tab"
                    data-bs-toggle="tab"
                    href="#about"
                    role="tab"
                    aria-controls="about"
                    aria-selected="true"
                  >
                    {name1}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="feature-line-tab"
                    data-bs-toggle="tab"
                    href="#feature"
                    role="tab"
                    aria-controls="feature"
                    aria-selected="false"
                  >
                    {name2}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="states-line-tab"
                    data-bs-toggle="tab"
                    href="#states"
                    role="tab"
                    aria-controls="states"
                    aria-selected="false"
                  >
                    {name3}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="partners-line-tab"
                    data-bs-toggle="tab"
                    href="#partners"
                    role="tab"
                    aria-controls="partners"
                    aria-selected="false"
                  >
                    {name4}
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-3" id="lineTabContent">
                <div
                  className="tab-pane fade show active"
                  id="about"
                  role="tabpanel"
                  aria-labelledby="home-line-tab"
                >
                  <AboutSection name1={name1} />
                </div>
                <div
                  className="tab-pane fade"
                  id="feature"
                  role="tabpanel"
                  aria-labelledby="feature-line-tab"
                >
                  <FeatureSection name2={name2} />
                </div>
                <div
                  className="tab-pane fade"
                  id="states"
                  role="tabpanel"
                  aria-labelledby="states-line-tab"
                >
                  <StatesSection name3={name3} />
                </div>
                <div
                  className="tab-pane fade"
                  id="partners"
                  role="tabpanel"
                  aria-labelledby="partners-line-tab"
                >
                  <PartnerSection name4={name4} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
