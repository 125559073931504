import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";

export default function Menu() {
  return (
    <div className="main-wrapper">
      <div>
        <Sidebar />
      </div>
      <div className="page-wrapper">
        <Navbar />
        {/* {children} */}
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}
