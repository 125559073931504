import React from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const PrintButton = ({ rootElementId, downloadFileName }) => {
  const downloadInvoice = () => {
    const input = document.getElementById(rootElementId);
    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      console.log("canvas", canvas.height, canvas.width);
      const imageWidth = 208;
      const imageHeight = (canvas.height * imageWidth) / canvas.width;
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, imageWidth, imageHeight);
      pdf.save(`${downloadFileName}`);
    });
  };
  return (
    <div>
      <button
        className="btn btn-outline-primary btn-icon-text float-end mt-3"
        onClick={downloadInvoice}
      >
        Generate PDF file
      </button>
    </div>
  );
};

export default PrintButton;
