import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import { FiAlertTriangle } from "react-icons/fi";

export default function HeaderDetails() {
  const [editContact, setEditContact] = useState(false);
  const [editOffer, setEditOffer] = useState(false);
  const [delShow, setDelShow] = useState(false);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);

  const handleEditContact = () => {
    setEditContact(true);
  };

  const handleEditOffer = () => {
    setEditOffer(true);
  };

  const handleEdit = () => {
    setEditShow(true);
  };

  const handleDelete = () => {
    setDelShow(true);
  };

  const handleAdd = () => {
    setShow(true);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setEditContact(false);
    setEditOffer(false);
    setDelShow(false);
    setShow(false);
    setEditShow(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <div>
                <h6 className="card-title">Header Section</h6>
              </div>
              <div className="card p-4 mb-3">
                <div className="row gx-4">
                  <div className="col-lg-6 my-3">
                    <div className="header-card">
                      <div className="d-flex justify-content-between">
                        <h5 className="card-about-title">Contact Number</h5>
                        <div>
                          <FaEdit
                            size={15}
                            className="me-2"
                            style={{ cursor: "pointer" }}
                            onClick={handleEditContact}
                          />
                        </div>
                      </div>
                      <div>
                        <p>9851256984</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 my-3">
                    <div className="header-card">
                      <div className="d-flex justify-content-between">
                        <h5 className="card-about-title">
                          Discount Offer Name
                        </h5>
                        <div>
                          <FaEdit
                            size={15}
                            className="me-2"
                            style={{ cursor: "pointer" }}
                            onClick={handleEditOffer}
                          />
                        </div>
                      </div>
                      <div>
                        <p>Clearance UPto 30% off</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card p-4">
                <div className="d-flex justify-content-between">
                  <h5 className="card-about-title">Navbar Items</h5>
                  <div>
                    <AiOutlinePlusCircle
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={handleAdd}
                    />
                  </div>
                </div>{" "}
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Link</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Home</td>
                      <td>https://ef.allgetz.com/</td>
                      <td>
                        <div className="form-check form-switch mb-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="formSwitch1"
                            // checked={item.status}
                            // onChange={() => handleStatus(item)}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <FaEdit
                            size={22}
                            color="green"
                            className="mx-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEdit()}
                          />
                          <MdOutlineDeleteOutline
                            size={24}
                            color="red"
                            className="mx-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDelete()}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit Contact */}
      <Modal
        show={editContact}
        centered
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Contact Number </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="forms-sample">
            <div className="row">
              <div className="col-sm-12 mb-3">
                <label htmlFor="name" className="form-label">
                  Phone Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="number"
                  placeholder="Phone number"
                  required
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary">
            Edit
          </button>
        </Modal.Footer>
      </Modal>
      {/* Edit Offer */}
      <Modal
        show={editOffer}
        centered
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Offer </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="forms-sample">
            <div className="row">
              <div className="col-sm-12 mb-3">
                <label htmlFor="name" className="form-label">
                  Offer
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="number"
                  placeholder="Offer"
                  required
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary">
            Edit
          </button>
        </Modal.Footer>
      </Modal>
      {/* Add Navbar items */}
      <Modal
        show={show}
        centered
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Navbar Items </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="forms-sample">
            <div className="row">
              <div className="col-sm-12 mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="number"
                  placeholder="Name"
                  required
                />
              </div>
              <div className="col-sm-12 mb-3">
                <label htmlFor="name" className="form-label">
                  Link
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="number"
                  placeholder="Link"
                  required
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary">
            Add
          </button>
        </Modal.Footer>
      </Modal>
      {/* Edit Navbar items */}
      <Modal
        show={editShow}
        centered
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Navbar Items </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="forms-sample">
            <div className="row">
              <div className="col-sm-12 mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="number"
                  placeholder="Name"
                  required
                />
              </div>
              <div className="col-sm-12 mb-3">
                <label htmlFor="name" className="form-label">
                  Link
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="number"
                  placeholder="Link"
                  required
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary">
            Edit
          </button>
        </Modal.Footer>
      </Modal>
      {/*Delete Navbar info  */}
      <Modal
        show={delShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Navbar Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="icontext">
            <FiAlertTriangle className="alert_icon py-2" />
            <h4>Do you really want to delete it?</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            // onClick={handleRemove}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
