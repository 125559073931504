import React from "react";
import HeaderDetails from "../components/Header/HeaderDetails";

export default function Header() {
  return (
    <div className="page-content">
      <HeaderDetails />
    </div>
  );
}
