import React from "react";
import FooterElement from "../components/Footer/FooterElement";

export default function Footer() {
  return (
    <div className="page-content">
      <FooterElement />
    </div>
  );
}
