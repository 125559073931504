import React, { useEffect, useState } from "react";
import URL from "../../configuration/config";
import Card from "../Elements/Card";
import { FaUsers } from "react-icons/fa";
import { BiBookBookmark } from "react-icons/bi";
import { AiOutlineShop, AiOutlineTag } from "react-icons/ai";

export default function TopHeader() {
  const [totalCustomer, setTotalCustomer] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [order, setOrder] = useState([]);
  // const order = [];

  useEffect(() => {
    const getUser = () => {
      URL.get("/clients")
        .then((response) => {
          setTotalCustomer(response.data.data.totalRows);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    // let count = 0;

    const getOrderDetail = () => {
      URL.get("/orders/all", {
        params: {
          limit: totalOrder,
        },
      })
        .then((response) => {
          const allOrders = response.data.data.orders;
          const filteredOrders = allOrders.filter(
            (order) => order.grand_total === order.paid
          );
          setOrder(filteredOrders);
          setTotalOrder(response.data.data.totalRows);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getProduct = () => {
      URL.get("/products")
        .then((result) => {
          if (result.status === 200) {
            setTotalProduct(result.data.data.totalRows);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    getUser();
    getOrderDetail();
    getProduct();
  }, [totalOrder]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap grid-margin">
        <div>
          <h4 className="mb-3 mb-md-0">Welcome to Dashboard</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xl-12 stretch-card">
          <div className="row flex-grow-1">
            <div className="col-md-3 grid-margin stretch-card">
              <Card
                title="Total Customer"
                amount={totalCustomer}
                icon=<FaUsers />
              />
            </div>
            <div className="col-md-3 grid-margin stretch-card">
              <Card
                title="Total Orders"
                amount={totalOrder}
                icon=<BiBookBookmark />
              />
            </div>
            <div className="col-md-3 grid-margin stretch-card">
              <Card
                title="Total Products"
                amount={totalProduct}
                icon=<AiOutlineShop />
              />
            </div>
            <div className="col-md-3 grid-margin stretch-card">
              <Card
                title="Total Sales"
                amount={order.length}
                icon=<AiOutlineTag />
              />
            </div>
          </div>
        </div>
      </div>{" "}
      {/* row */}
    </div>
  );
}
