import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import URL from "../../configuration/config";
import Loading from "../Elements/Loading";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineSearch } from "react-icons/ai";

export default function OrderTable() {
  const [orderDetail, setOrderDetail] = useState([]);
  const [rows, setRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState({
    ereference_No: "",
    epaying_amount: "",
    ereceived_amount: "",
    note: "",
  });
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [payType, setPayType] = useState("");
  const [paymentinstallment, setPaymentInstallment] = useState([]);
  const [showPayment, setShowPayment] = useState(false);
  const [payAmountError, setPayAmountError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [orderId, setOrderID] = useState(null);
  const [modalLoad, setModalLoad] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredOrder, setFilteredOrder] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    getOrderDetail(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const getOrderDetail = (page, rowsPerPage) => {
    setLoading(true);
    URL.get("/orders/all", {
      params: {
        page: page,
        limit: rowsPerPage,
      },
    })
      .then((response) => {
        setLoading(false);
        setOrderDetail(response.data.data.orders);
        setRows(response.data.data.totalRows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Validation = () => {
    let payAmountError = "";
    let typeError = "";
    let isValid = true;

    if (!paymentDetail.epaying_amount) {
      payAmountError = "Please enter a paying amount";
      isValid = false;
    }

    if (!payType) {
      typeError = "Please choose a payment type";
      isValid = false;
    }

    if (paymentDetail.epaying_amount > paymentDetail.ereceived_amount) {
      payAmountError = "Paying amount must not be greater than received amount";
      isValid = false;
    }

    setPayAmountError(payAmountError);
    setTypeError(typeError);
    return isValid;
  };

  //columns of table
  const columns = [
    {
      name: "Date",
      selector: (row) => {
        const timestamp = row.created_at;
        const date = new Date(timestamp);
        const dateOnly = date.toISOString().slice(0, 10);
        return <p>{dateOnly}</p>;
      },
      sortable: true,
      minWidth: 500,
    },
    {
      name: "Reference",
      selector: (row) => row.ref,
      sortable: true,
    },
    {
      name: "Customer",
      selector: (row) => row.customer.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        if (row.order_status === "order_placed") {
          return (
            <span className="badge border border-primary text-primary">
              Order Placed
            </span>
          );
        } else {
          return (
            <span className="badge border border-danger text-danger">
              Order Not Placed
            </span>
          );
        }
      },
      sortable: true,
      minWidth: 500,
    },
    {
      name: "Items ordered",
      selector: (row) => row.orderitems.length,
      sortable: true,
      minWidth: 500,
    },
    {
      name: "Grand Total",
      selector: (row) => {
        return <p> Rs {row.grand_total}</p>;
      },
      sortable: true,
    },
    {
      name: "Paid",
      selector: (row) => {
        return <p> Rs {row.paid}</p>;
      },
      sortable: true,
    },
    {
      name: "Due",
      selector: (row) => {
        return <p> Rs {row.due}</p>;
      },
      sortable: true,
    },
    {
      name: "Payment Type",
      selector: (row) => {
        if (row.payment_type === "cash_on_delivery") {
          return (
            <span className="badge  border border-success text-success">
              Cash on delivery
            </span>
          );
        } else if (row.payment_type === "fonepay") {
          return (
            <span className="badge border border-primary text-primary">
              Fonepay
            </span>
          );
        } else {
          return (
            <span className="badge border border-danger text-danger">
              Unpaid
            </span>
          );
        }
      },
      sortable: true,
      minWidth: 500,
    },
    {
      name: "Payment Status",
      selector: (row) => {
        if (row.due === row.grand_total) {
          return (
            <span className="badge border border-danger text-danger">
              Unpaid
            </span>
          );
        } else if (row.due === 0) {
          return (
            <span className="badge border border-success text-success">
              Paid
            </span>
          );
        } else if (row.paid >= 10) {
          return (
            <span className="badge  border border-primary text-primary">
              Partial
            </span>
          );
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      style: { overflow: "visible" },
      selector: (row) => {
        return (
          <div className="row">
            <div className="dropdown">
              <p
                type="button"
                // className="btn btn-secondary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <BsThreeDotsVertical />
              </p>
              <div className="dropdown-menu">
                <Link to={"/order/" + row.id} className="dropdown-item py-2">
                  View Order Detail
                </Link>
                {row.due === 0 ? (
                  <span
                    className="dropdown-item py-2"
                    onClick={handleWarning}
                    style={{ cursor: "pointer" }}
                  >
                    Make Payments
                  </span>
                ) : (
                  <span
                    className="dropdown-item py-2"
                    onClick={() => handleCheckPayment(row)}
                    style={{ cursor: "pointer" }}
                  >
                    Make Payments
                  </span>
                )}
                <span
                  className="dropdown-item py-2"
                  onClick={() => getPayment(row.id)}
                  style={{ cursor: "pointer" }}
                >
                  Check Payments
                </span>
              </div>
            </div>
          </div>
        );
      },
      sortable: true,
      minWidth: 500,
    },
  ];

  const handleCheckPayment = (row) => {
    setShow(true);
    console.log(row);
    setOrderID(row.id);
    setPaymentDetail({
      ereference_No: row.ref,
      epaying_amount: 0,
      ereceived_amount: row.due,
      note: "",
    });
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!orderDetail.length) return;
    getOrderDetail(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    getOrderDetail(page, rowsPerPage);
    setCurrentPage(page);
  };

  //Modal close
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShow(false);
    setShowPayment(false);
  };

  const handleChange = (e) => {
    setPaymentDetail({ ...paymentDetail, [e.target.name]: e.target.value });
  };

  const handlePaymentType = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("value");
    setPayType(option);
  };

  const getPayment = (id) => {
    setModalLoad(true);
    console.log(id);
    setShowPayment(true);
    const formData = new FormData();
    formData.append("order_id", id);
    URL.post("/order/all-payments", formData)
      .then((response) => {
        console.log(response);
        setModalLoad(false);
        setPaymentInstallment(response.data.data.payments);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const makePayment = (event) => {
    console.log(show);
    event.preventDefault();
    const isValid = Validation();
    if (isValid) {
      setLoading(true);
      const formData = new FormData();
      formData.append("order_id", orderId);
      formData.append("paying_amount", paymentDetail.epaying_amount);
      formData.append("payment_type", payType);
      formData.append("note", paymentDetail.note);
      URL.post("/order/make-payment", formData)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setPaymentDetail({
              epaying_amount: "",
              note: "",
            });
            setShow(false);
            setLoading(false);
            toast.success(response.data.message);
            getOrderDetail(currentPage, rowsPerPage);
          }
        })
        .catch((error) => {
          const { status, data } = error.response;
          if (status === 404) {
            toast.error(data.message);
          }
        });
    }
  };

  const handleWarning = () => {
    console.log("abacs");
    toast.warning("The paymet is already completed");
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
  };
  const filterByDate = (startDate, endDate, orders) => {
    return orders.filter((order) => {
      const timestamp = order.created_at;
      const date = new Date(timestamp);
      const dateOnly = date.toISOString().slice(0, 10);
      const dateFilter =
        startDate && endDate
          ? dateOnly >= startDate && dateOnly <= endDate
          : true;
      return dateFilter;
    });
  };

  const filterBySearch = (search, orders) => {
    return orders.filter((order) => {
      const searchContain = search
        ? order.customer.name.toLowerCase().includes(search.toLowerCase())
        : true;
      return searchContain;
    });
  };

  useEffect(() => {
    let filtered;

    if (search) {
      filtered = filterBySearch(search, orderDetail);
    } else if (startDate || endDate) {
      filtered = filterByDate(startDate, endDate, orderDetail);
    } else if (search && (startDate || endDate)) {
      const filteredByDate = filterByDate(startDate, endDate, orderDetail);
      const filteredBySearch = filterBySearch(search, orderDetail);
      filtered = filteredByDate.filter((order) =>
        filteredBySearch.includes(order)
      );
    }

    setFilteredOrder(filtered);
  }, [startDate, endDate, search, orderDetail]);

  console.log(filteredOrder);
  // useEffect(() => {
  //   setFilteredOrder(
  //     orderDetail.filter((row) => {
  //       const timestamp = row.created_at;
  //       const date = new Date(timestamp);
  //       const dateOnly = date.toISOString().slice(0, 10);
  //       const searchContain = search
  //         ? row.customer.name.toLowerCase().includes(search.toLowerCase())
  //         : true;
  //       const dateFilter =
  //         startDate && endDate
  //           ? dateOnly >= startDate && dateOnly <= endDate
  //           : true;
  //       console.log("startDate:", startDate, "endDate:", endDate);
  //       console.log("row.created_at:", dateOnly);
  //       return dateFilter && searchContain;
  //     })
  //   );
  // }, [startDate, endDate, search, orderDetail]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-md-6">
                    <h6 className="card-title">All Orders</h6>
                  </div>
                </div>
                <div className="d-flex flex-column flex-lg-row mb-3">
                  <div style={{ width: "100%" }}>
                    <div className="input-group">
                      <div className="input-group-text">
                        <AiOutlineSearch />
                      </div>
                      <input
                        type="text"
                        className="form-control mr-3"
                        id="navbarForm"
                        placeholder="Search here..."
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    <span className="mx-1 ">From:</span>
                  </div>
                  <div style={{ width: "100%" }}>
                    <input
                      type="date"
                      className="form-control mr-3"
                      placeholder="From.."
                      selected={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="mx-1">To:</span>
                  </div>
                  <div style={{ width: "100%" }}>
                    <input
                      type="date"
                      className="form-control mr-3"
                      selected={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={
                      filteredOrder.length === 0 ? orderDetail : filteredOrder
                    }
                    defaultSortFieldId
                    pagination
                    paginationServer
                    paginationTotalRows={rows}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={rowsPerPage}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="500px"
                    // conditionalRowStyles={conditionalRowStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Make Payments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="forms-sample">
            <div className="row mb-3">
              <div className="col-sm-6 mb-1">
                <label htmlFor="name" className="form-label">
                  Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                />
              </div>
              <div className="col-sm-6 mb-1">
                <label htmlFor="name" className="form-label">
                  Reference No
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="ereference_No"
                  placeholder="Reference No"
                  required
                  value={paymentDetail.ereference_No}
                  onChange={handleChange}
                  disabled
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6 mb-1">
                <label htmlFor="name" className="form-label">
                  Receiving Amount
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="name"
                  name="ereceived_amount"
                  placeholder="Receiving Amount"
                  required
                  value={paymentDetail.ereceived_amount}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-6 mb-1">
                <label htmlFor="name" className="form-label">
                  Paying Amount
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="name"
                  placeholder="Paying Amount"
                  required
                  name="epaying_amount"
                  value={paymentDetail.epaying_amount}
                  onChange={handleChange}
                />
                <div className="mb-3" style={{ color: "red" }}>
                  {payAmountError}
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6 mb-1">
                <label htmlFor="name" className="form-label">
                  Remaining Amount
                </label>
                <p className="balance">
                  {paymentDetail.ereceived_amount -
                    paymentDetail.epaying_amount}
                </p>
              </div>
              <div className="col-sm-6 mb-1">
                <label className="form-label">Paying Choice</label>
                <select
                  className="form-select form-select-sm mb-3"
                  defaultValue={"DEFAULT"}
                  onChange={handlePaymentType}
                >
                  <option selected disabled value="DEFAULT">
                    Select Paying Choice
                  </option>
                  <option value="cash_on_delivery">Cash</option>
                  <option value="fonepay">FonePay</option>
                </select>
                <div className="mb-3" style={{ color: "red" }}>
                  {typeError}
                </div>
              </div>
            </div>
            <div className="row mb-3 mx-1">
              <label className="form-label">Note</label>
              <textarea
                className="form-control"
                name="note"
                rows="5"
                value={paymentDetail.note}
                onChange={handleChange}
              ></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            data-bs-dismiss="modal"
            onClick={makePayment}
          >
            Pay
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showPayment}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Check Payments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalLoad ? (
            <Loading />
          ) : (
            <div className="table-responsive pt-3">
              {paymentinstallment.length === 0 ? (
                <p className="text-center my-3">No Payment done till now</p>
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Reference</th>
                      <th>Amount </th>
                      <th>Paid by</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentinstallment.map((item, index) => {
                      const timestamp = item.created_at;
                      const date = new Date(timestamp);
                      const dateOnly = date.toISOString().slice(0, 10);
                      return (
                        <tr key={index}>
                          <td>{dateOnly}</td>
                          <td>{item.ref}</td>
                          <td>Rs {item.paying_amount}</td>
                          <td>
                            {item.payment_type === "cash_on_delivery"
                              ? "cash"
                              : item.payment_type}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
}
