import React, { useState, useEffect } from "react";
import URL from "../../../configuration/config";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../Elements/Loading";

export default function FooterSection1() {
  const name = "Footer Section #1";
  const [footersection1, setFooterSection1] = useState({
    title: "",
    description: "",
    imageLink: "",
  });
  const [footerElements, setFooterElements] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getFooterElements = () => {
      setLoading(true);
      URL.get("/footer-payload-system/all")
        .then((response) => {
          setLoading(false);
          const footerData = response.data.data.footer_payload_system;
          setFooterElements(footerData);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    getFooterElements();
  }, []);

  useEffect(() => {
    const getFooterElementsByName = () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      URL.post("/footer-payload-system/get", formData)
        .then((response) => {
          if (response.status === 200) {
            setData(response.data.data.footer_payload_system);

            setFooterSection1(
              JSON.parse(response.data.data.footer_payload_system.payload)
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          const { status } = error.response;
          if (status === 404) {
            setData(null);
          }
        });
    };
    for (let i = 0; i < footerElements.length; i++) {
      const item = footerElements[i];
      if (item.name === name) {
        getFooterElementsByName();
      }
    }
  }, [footerElements]);

  const handleChange = (e) => {
    setFooterSection1({ ...footersection1, [e.target.name]: e.target.value });
  };

  const handleAddFooterSection = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("payload", JSON.stringify(footersection1));

    URL.post("/footer-payload-system/add-new", formData)
      .then((response) => {
        setLoading(false);
        setData(response.data.data.footer_payload_system);
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditFooterSection = (footerData) => {
    setLoading(true);
    const footerUpdate = [...footerElements];
    const index = footerElements.findIndex((data) => data.id === footerData.id);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("payload", JSON.stringify(footersection1));
    formData.append("_method", "PUT");
    if (index >= 0) {
      footerUpdate[index].name = name;
      footerUpdate[index].payload = JSON.stringify(footersection1);
      URL.post("/footer-payload-system/update", formData)
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  return (
    <div>
      {data.length === 0 ? (
        <div>
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {name}
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse "
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {loading ? (
                <Loading />
              ) : (
                <div>
                  <div className="mb-2">
                    <label htmlFor="name" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Title"
                      required
                      name="title"
                      value={footersection1.title}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="name" className="form-label">
                      Image Link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="link"
                      placeholder="Image Link"
                      required
                      name="imageLink"
                      value={footersection1.imageLink}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="name" className="form-label">
                      Description
                    </label>

                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                      name="description"
                      value={footersection1.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-primary px-5"
                      onClick={handleAddFooterSection}
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {name}
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse "
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {loading ? (
                <Loading />
              ) : (
                <div>
                  <div className="mb-2">
                    <label htmlFor="name" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Title"
                      required
                      name="title"
                      value={footersection1.title}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="name" className="form-label">
                      Image Link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="link"
                      placeholder="Image Link"
                      required
                      name="imageLink"
                      value={footersection1.imageLink}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="name" className="form-label">
                      Description
                    </label>

                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                      name="description"
                      value={footersection1.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-primary px-5"
                      onClick={() => handleEditFooterSection(data)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
