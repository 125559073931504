import React from "react";
import FeatureItem from "../components/FeaturedProduct/FeatureItem";

export default function FeaturedProduct() {
  return (
    <div className="page-content">
      <FeatureItem />
    </div>
  );
}
