import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import URL from "../../configuration/config";

export default function PasswordChange({ setLoading }) {
  const [values, setValues] = useState({
    old: {
      password: "",
      showPassword: false,
    },
    new: {
      password: "",
      showPassword: false,
    },
    confirm: {
      password: "",
      showPassword: false,
    },
  });
  const [error, setError] = useState({
    old: "",
    new: "",
    confirm: "",
  });

  const handleChange = (stateName, propName) => (event) => {
    setValues({
      ...values,
      [stateName]: {
        ...values[stateName],
        [propName]: event.target.value,
      },
    });
  };

  const handleClickShowPassword = (stateName) => () => {
    setValues({
      ...values,
      [stateName]: {
        ...values[stateName],
        showPassword: !values[stateName].showPassword,
      },
    });
  };

  const Validation = () => {
    let isValid = true;
    let oldPassword = "";
    let newPassword = "";
    let confirmPassword = "";

    if (!values.old.password) {
      console.log("fgdfd");
      oldPassword = "Please enter old password";
      isValid = false;
    }
    if (!values.new.password) {
      newPassword = "Please enter new password";
      isValid = false;
    }
    if (!values.confirm.password) {
      confirmPassword = "Please enter confirm password";
      isValid = false;
    }
    if (values.new.password !== values.confirm.password) {
      confirmPassword = "New and confirm password doesnot match";
      isValid = false;
    }

    setError({ old: oldPassword, new: newPassword, confirm: confirmPassword });

    return isValid;
  };

  const handleChangePassword = () => {
    const Valid = Validation();
    if (Valid) {
      setLoading(true);
      const formData = new FormData();
      formData.append("old_password", values.old.password);
      formData.append("new_password", values.new.password);
      formData.append("confirm_password", values.confirm.password);
      formData.append("_method", "PUT");
      URL.post("/profile/update-password", formData)
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            console.log(response);
            setLoading(false);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          const { status } = error.response;
          if (status === 404) {
            toast.error("Old Password is incorrect");
          }
        });
    }
  };

  return (
    <div>
      <div className="card">
        <h4 className="pt-4 px-4">Change Password</h4>
        <hr className="mx-4" />
        <div className="py-4 px-4">
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Current Password
            </label>
            <div className="textfield_icon ">
              <input
                type={values.old.showPassword ? "text" : "password"}
                className="form-control"
                id="name"
                placeholder="Current Password"
                required
                value={values.old.password}
                onChange={handleChange("old", "password")}
              />
              <span
                onClick={handleClickShowPassword("old")}
                className="eye-icon"
              >
                {values.old.showPassword ? (
                  <AiFillEyeInvisible />
                ) : (
                  <AiFillEye />
                )}
              </span>
            </div>
          </div>
          <div className="mb-3" style={{ color: "red" }}>
            {error.old}
          </div>
          <div className=" mb-2">
            <label htmlFor="number" className="form-label">
              New Password
            </label>
            <div className="textfield_icon ">
              <input
                type={values.new.showPassword ? "text" : "password"}
                className="form-control"
                id="number"
                placeholder="New Password"
                required
                value={values.new.password}
                onChange={handleChange("new", "password")}
              />
              <span
                onClick={handleClickShowPassword("new")}
                className="eye-icon"
              >
                {values.new.showPassword ? (
                  <AiFillEyeInvisible />
                ) : (
                  <AiFillEye />
                )}
              </span>
            </div>
          </div>
          <div className="mb-3" style={{ color: "red" }}>
            {error.new}
          </div>
          <div className="mb-2">
            <label htmlFor="email" className="form-label">
              Confirm New Password
            </label>
            <div className="textfield_icon">
              <input
                type={values.confirm.showPassword ? "text" : "password"}
                className="form-control"
                id="email"
                placeholder="Confirm New Password"
                required
                value={values.confirm.password}
                onChange={handleChange("confirm", "password")}
              />
              <span
                onClick={handleClickShowPassword("confirm")}
                className="eye-icon"
              >
                {values.confirm.showPassword ? (
                  <AiFillEyeInvisible />
                ) : (
                  <AiFillEye />
                )}
              </span>
            </div>
          </div>
          <div className="mb-3" style={{ color: "red" }}>
            {error.confirm}
          </div>
          <button className="btn btn-primary" onClick={handleChangePassword}>
            Edit
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
