import React from "react";
import RegisterDetails from "../components/Login/RegisterDetails";

export default function Register() {
  return (
    <div>
      <RegisterDetails />
    </div>
  );
}
