import React from "react";
import LoginDetails from "../components/Login/LoginDetails";

export default function login() {
  return (
    <div>
      <LoginDetails />
    </div>
  );
}
