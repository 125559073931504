import React from "react";
import SingleContactPage from "../components/Contact/SingleContactPage";

export default function SingleContact() {
  return (
    <div className="page-content">
      <SingleContactPage />
    </div>
  );
}
