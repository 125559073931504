//  react imports
import { useState, useRef } from "react"
// configs
import authURL from "../../configuration/config"
import { useTheme } from "../../context/ThemeProvider"
// third party imports
import JoditEditor from "jodit-react"
import { ToastContainer, toast } from "react-toastify"
import Loading from "../Elements/Loading"
import {
  SingleImageDropzoneComponent,
  MultiImageDropzoneComponent
} from "../Dropbox/DropzoneComponent"

export default function ProductForm() {
  const { categoryData, brandData } = useTheme()
  // form states
  const [name, setName] = useState("")
  const [category, setCategory] = useState("")
  const [brand, setBrand] = useState("")
  const [quantity, setQuantity] = useState("")
  const [cost, setCost] = useState("")
  const [price, setPrice] = useState("")
  const [description, setDescription] = useState("")
  const [additionalDescription, setAdditionalDescription] = useState("")
  const [shipping, setShipping] = useState("")
  const [mainImage, setMainImage] = useState([])
  const [multiImages, setMultiImages] = useState([])
  // validation states
  const [nameError, setNameError] = useState("")
  const [quantityError, setQuantityError] = useState("")
  const [priceError, setPriceError] = useState("")
  const [costError, setCostError] = useState("")
  const [mainImgError, setMainImgError] = useState("")
  const [multiImgError, setMultiImgError] = useState("")
  // refs
  const editorRef = useRef(null)
  const mainImageRef = useRef()
  const multiImageRef = useRef()

  const [loading, setLoading] = useState(false)

  // form field validations
  const Validation = () => {
    let nameError = ""
    let quantityError = ""
    let costError = ""
    let priceError = ""
    let mainImageError = ""
    let multiImageError = ""
    let isValid = true

    if (!name) {
      nameError = "This field is required"
      isValid = false
    }

    if (!quantity) {
      quantityError = "This field is required"
      isValid = false
    }

    if (!cost) {
      costError = "This field is required"
      isValid = false
    }

    if (!price) {
      priceError = "This field is required"
      isValid = false
    }

    if (mainImageRef.current !== undefined) {
      if (mainImageRef.current.clientWidth > 800) {
        mainImageError = "The width of image must be less than 800px"
        isValid = false
      }
      if (
        mainImageRef.current.clientWidth !== mainImageRef.current.clientHeight
      ) {
        mainImageError =
          "The width and height of image must be same Eg:(500px * 500px)"
        isValid = false
      }
    }
    if (multiImageRef.current !== undefined) {
      if (multiImageRef.current.clientWidth > 800) {
        multiImageError = "The width of image must be less than 800px"
        isValid = false
      }
      if (
        multiImageRef.current.clientWidth !== multiImageRef.current.clientHeight
      ) {
        multiImageError =
          "The width and height of image must be same Eg:(500px * 500px)"
        isValid = false
      }
    }

    setNameError(nameError)
    setQuantityError(quantityError)
    setCostError(costError)
    setPriceError(priceError)
    setMainImgError(mainImageError)
    setMultiImgError(multiImageError)
    return isValid
  }

  const handleCategory = (e) => {
    const index = e.target.selectedIndex
    const el = e.target.childNodes[index]
    const option = el.getAttribute("id")
    setCategory(option)
  }

  const handleBrand = (e) => {
    const index = e.target.selectedIndex
    const el = e.target.childNodes[index]
    const option = el.getAttribute("id")
    setBrand(option)
  }

  // runs when form is submitted
  const handleAddProduct = () => {
    const isValid = Validation()
    if (isValid) {
      setLoading(true)
      const formData = new FormData()
      formData.append("name", name)
      formData.append("cost", cost)
      formData.append("price", price)
      formData.append("quantity", quantity)
      formData.append("image", mainImage[0]) // Main Image Upload
      multiImages.forEach((file) => {
        formData.append("images[]", file) // Multiple Image upload
      })
      formData.append("category_id", category)
      formData.append("brand_id", brand)
      formData.append("description", description)
      formData.append("additional_information", additionalDescription)
      formData.append("shipping_returns", shipping)
      formData.append("status", false)
      authURL
        .post("/products", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          if (response.status === 200) {
            setLoading(false)
            setName("")
            setCost("")
            setPrice("")
            setQuantity("")
            setMainImage([])
            setMultiImages([])
            setCategory("")
            setBrand("")
            setDescription("")
            setAdditionalDescription("")
            setShipping("")
            toast.success(response.data.message)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 stretch-card">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Add Product</h6>
                <form className="forms-sample">
                  <div className="row">
                    <div className="col-sm-12 mb-1">
                      <label htmlFor="name" className="form-label">
                        Product Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Product Name"
                        required
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                      />
                    </div>
                    <div className="mb-3" style={{ color: "red" }}>
                      {nameError}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 mb-3">
                      <label
                        htmlFor="exampleFormControlSelect1"
                        className="form-label"
                      >
                        Select Category
                      </label>
                      <select
                        className="form-select"
                        id="exampleFormControlSelect1"
                        onChange={handleCategory}
                        defaultValue={"DEFAULT"}
                      >
                        <option value={"DEFAULT"} disabled>
                          Select Category
                        </option>
                        {categoryData.map((item, i) => {
                          return (
                            <option value={item.name} key={i} id={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <label
                        htmlFor="exampleFormControlSelect1"
                        className="form-label"
                      >
                        Select Brand
                      </label>
                      <select
                        className="form-select"
                        id="exampleFormControlSelect1"
                        onChange={handleBrand}
                        defaultValue={"DEFAULT"}
                      >
                        <option value={"DEFAULT"} disabled>
                          Select Brand
                        </option>
                        {brandData.map((item, i) => {
                          return (
                            <option value={item.name} key={i} id={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 mb-1">
                      <label htmlFor="name" className="form-label">
                        Product Quantity
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="name"
                        placeholder="Product Quantity"
                        required
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                      <div className="mb-3" style={{ color: "red" }}>
                        {quantityError}
                      </div>
                    </div>
                    <div className="col-sm-4 mb-1">
                      <label htmlFor="name" className="form-label">
                        Product Cost
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="name"
                        placeholder="Product Cost"
                        required
                        value={cost}
                        onChange={(e) => setCost(e.target.value)}
                      />
                      <div className="mb-3" style={{ color: "red" }}>
                        {costError}
                      </div>
                    </div>
                    <div className="col-sm-4 mb-1">
                      <label htmlFor="name" className="form-label">
                        Product Price
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="name"
                        placeholder="Product Price"
                        required
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                      <div className="mb-3" style={{ color: "red" }}>
                        {priceError}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 mb-3">
                      <label htmlFor="name" className="form-label">
                        Description
                      </label>
                      <JoditEditor
                        ref={editorRef}
                        value={description}
                        onBlur={(content) => setDescription(content)}
                      />
                    </div>
                    <div className="col-sm-12 mb-3">
                      <label htmlFor="name" className="form-label">
                        Additional Description
                      </label>
                      <JoditEditor
                        ref={editorRef}
                        value={additionalDescription}
                        onBlur={(content) => setAdditionalDescription(content)}
                      />
                    </div>
                    <div className="col-sm-12 mb-3">
                      <label htmlFor="name" className="form-label">
                        Shipping Returns
                      </label>
                      <JoditEditor
                        ref={editorRef}
                        value={shipping}
                        onBlur={(content) => setShipping(content)}
                      />
                    </div>
                    <div className="col-sm-12 mb-3">
                      <label htmlFor="name" className="form-label">
                        Main Image
                      </label>
                      <div>
                        <div className="mb-3" style={{ color: "red" }}>
                          {mainImgError}
                        </div>
                        <SingleImageDropzoneComponent
                          image={mainImage}
                          setImage={setMainImage}
                          imgRef={mainImageRef}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mb-3">
                      <label htmlFor="name" className="form-label">
                        Multiple Images (Max 7)
                      </label>
                      <div>
                        <div className="mb-3" style={{ color: "red" }}>
                          {multiImgError}
                        </div>
                        <MultiImageDropzoneComponent
                          images={multiImages}
                          setImages={setMultiImages}
                          imgRef={multiImageRef}
                          maxFile={7}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <div className="mb-3 d-flex flex-column align-items-end">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleAddProduct}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  )
}
