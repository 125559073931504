import React from "react";
import BrandForm from "../components/Brand/BrandForm";

export default function Brand() {
  return (
    <div className="page-content">
      <BrandForm />
    </div>
  );
}
