import React from "react";
import CategoryForm from "../components/Category/CategoryForm";

export default function Category() {
  return (
    <div className="page-content">
      <CategoryForm />
    </div>
  );
}
