import React, { useState, useRef, useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiAlertTriangle } from "react-icons/fi";
import Modal from "react-bootstrap/Modal";
import JoditEditor from "jodit-react";
import Loading from "../Elements/Loading";
import URL from "../../configuration/config";
import { toast } from "react-toastify";

export default function PartnerSection({ name4 }) {
  const [deletePartner, setDeletePartner] = useState(false);
  const editorRef = useRef(null);
  const [addElement, setAddElement] = useState(false);
  const [partnerSection, setPartnerSection] = useState({
    mainHeading: "",
    subHeading: "",
    partnerList: [],
  });
  const partnerListSection = {};
  const [partnerElements, setPartnerElements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [partnerData, setPartnerData] = useState([]);

  useEffect(() => {
    const getPartnerElements = () => {
      setLoading(true);
      URL.get("/cms-payload-system/all")
        .then((response) => {
          setLoading(false);
          const data = response.data.data.cms_payload_system;
          setPartnerElements(data);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getPartnerElements();
  }, []);

  useEffect(() => {
    const getPartnerElementsByName = () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name4);
      URL.post("/cms-payload-system/get", formData)
        .then((response) => {
          if (response.status === 200) {
            setPartnerData(response.data.data.cms_payload_system);
            setPartnerSection(
              JSON.parse(response.data.data.cms_payload_system.payload)
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          const { status } = error.response;
          if (status === 404) {
            setPartnerData(null);
          }
        });
    };

    for (let i = 0; i < partnerElements.length; i++) {
      const item = partnerElements[i];
      if (item.name === name4) {
        getPartnerElementsByName();
      }
    }
  }, [partnerElements, name4]);

  const handleAddPartners = () => {
    setAddElement(true);
    const newLink = [
      ...partnerSection.partnerList,
      {
        partnerListSection,
      },
    ];
    setPartnerSection({ ...partnerSection, partnerList: newLink });
  };

  const handleDeletePartners = () => {
    setDeletePartner(true);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDeletePartner(false);
  };

  const handleChangeHeading = (e) => {
    setPartnerSection({ ...partnerSection, mainHeading: e });
  };

  const handleChangeSubHeading = (e) => {
    setPartnerSection({ ...partnerSection, subHeading: e });
  };

  const handleChange = (e, index) => {
    const updatedLinks = [...partnerSection.partnerList];
    const updatedLinkSection = {
      ...updatedLinks[index].partnerListSection, // Get the current section data for the current index
      [e.target.name]: e.target.value,
    };
    const updatedLink = {
      partnerListSection: updatedLinkSection,
    };
    updatedLinks[index] = updatedLink; // Update the current array element with the updated link object
    setPartnerSection({
      ...partnerSection,
      partnerList: updatedLinks,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name4);
    formData.append("payload", JSON.stringify(partnerSection));

    URL.post("/cms-payload-system/add-new", formData)
      .then((response) => {
        if (response.status === 200) {
          setPartnerData(response.data.data.cms_payload_system);
          setLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleEdit = (partnerData) => {
    setLoading(true);
    const partnerUpdate = [...partnerElements];
    const index = partnerElements.findIndex(
      (data) => data.id === partnerData.id
    );
    const formData = new FormData();
    formData.append("name", name4);
    formData.append("payload", JSON.stringify(partnerSection));
    formData.append("_method", "PUT");
    if (index >= 0) {
      partnerUpdate[index].name = name4;
      partnerUpdate[index].payload = JSON.stringify(partnerSection);
      URL.post("/cms-payload-system/update", formData)
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {partnerData.length === 0 ? (
            <div className="mb-5">
              <div className="mb-4">
                <div className="d-flex justify-content-between mb-3">
                  <h6>Add Partners Description</h6>
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Heading
                  </label>
                  <JoditEditor
                    ref={editorRef}
                    onBlur={(e) => handleChangeHeading(e)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Sub-Heading
                  </label>
                  <JoditEditor
                    ref={editorRef}
                    onBlur={(e) => handleChangeSubHeading(e)}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="mt-3">Partners</h6>
                <div className="card-icon mb-3" onClick={handleAddPartners}>
                  <AiOutlinePlusCircle />
                </div>
              </div>
              {addElement && (
                <div>
                  {partnerSection.partnerList.map((item, index) => {
                    const imageField = `image`;
                    const imageLinkField = `imageLink`;
                    return (
                      <div className="row" key={index}>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="name" className="form-label">
                            Image
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Image"
                            name={imageField}
                            value={item.partnerListSection.image}
                            onChange={(e) => handleChange(e, index)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="name" className="form-label">
                            Image Link
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Image Link"
                            name={imageLinkField}
                            value={item.partnerListSection.imageLink}
                            onChange={(e) => handleChange(e, index)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="mt-3">
                <button
                  className="btn btn-primary float-end"
                  type="button"
                  onClick={handleAdd}
                >
                  Add
                </button>
              </div>
            </div>
          ) : (
            <div className="mb-5">
              <div className="mb-4">
                <div className="d-flex justify-content-between mb-3">
                  <h6>Add Partners Description</h6>
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Heading
                  </label>
                  <JoditEditor
                    ref={editorRef}
                    onBlur={(e) => handleChangeHeading(e)}
                    value={partnerSection.mainHeading}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Sub-Heading
                  </label>
                  <JoditEditor
                    ref={editorRef}
                    value={partnerSection.subHeading}
                    onBlur={(e) => handleChangeSubHeading(e)}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="mt-3">Partners</h6>
                <div className="card-icon mb-3" onClick={handleAddPartners}>
                  <AiOutlinePlusCircle />
                </div>
              </div>
              {/* {addElement && ( */}
              <div>
                {partnerSection.partnerList.map((item, index) => {
                  const imageField = `image`;
                  const imageLinkField = `imageLink`;
                  return (
                    <div className="row" key={index}>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="name" className="form-label">
                          Image
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Image"
                          name={imageField}
                          value={item.partnerListSection.image}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                          Image Link
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Image Link"
                          name={imageLinkField}
                          value={item.partnerListSection.imageLink}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* )} */}
              <div className="mt-3">
                <button
                  className="btn btn-primary float-end"
                  type="button"
                  onClick={() => handleEdit(partnerData)}
                >
                  Edit
                </button>
              </div>
            </div>
          )}

          {/*Delete States  */}
          <Modal
            show={deletePartner}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Feature Section</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="icontext">
                <FiAlertTriangle className="alert_icon py-2" />
                <h4>Do you really want to delete it?</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                // onClick={handleRemove}
              >
                Delete
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
}
