import React from "react";
import TermsCondition from "../components/TermsCondition/TermsCondition";

export default function Terms() {
  return (
    <div className="page-content">
      <TermsCondition />
    </div>
  );
}
