import React from "react";
import BannerComponent from "../components/Banner/BannerComponent";

export default function Banner() {
  return (
    <div className="page-content">
      <BannerComponent />
    </div>
  );
}
