import React, { useState, useRef } from "react"
import URL from "../../configuration/config"
import JoditEditor from "jodit-react"
import Loading from "../Elements/Loading"
import SingleImageDropzoneComponent from "../Dropbox/DropzoneComponent"
import { toast, ToastContainer } from "react-toastify"

export default function BrandForm() {
  const editorRef = useRef(null)
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [nameError, setNameError] = useState("")
  const [descriptionError, setDescriptionError] = useState("")
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])

  //Validation
  const Validation = () => {
    let nameError = ""
    let descriptionError = ""
    let isValid = true

    if (!name) {
      nameError = "This field is required"
      isValid = false
    }
    if (!description) {
      descriptionError = "This field is required"
      isValid = false
    }
    setNameError(nameError)
    setDescriptionError(descriptionError)
    return isValid
  }

  //Add brand
  const handleAddBrand = () => {
    const Valid = Validation()
    if (Valid) {
      setLoading(true)
      const formData = new FormData()
      formData.append("name", name)
      formData.append("description", description)
      formData.append("image", files[0])
      URL.post("/brands", formData, {
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then((result) => {
          if (result.status === 200) {
            setLoading(false)
            setName("")
            setDescription("")
            setFiles([])
            toast.success(result.data.message)
          }
        })
        .catch((err) => {
          const { status } = err.response
          if (status === 404) {
            setLoading(false)
            toast.error("The name has already taken")
          }
        })
    }
  }

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 stretch-card">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Add Brand</h6>
                <form className="forms-sample">
                  <div className="row">
                    <div className="col-sm-12 mb-1">
                      <label htmlFor="name" className="form-label">
                        Brand Name
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="name"
                        placeholder="Brand Name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="mb-3" style={{ color: "red" }}>
                      {nameError}
                    </div>
                    <div className="col-sm-12 mb-1">
                      <label htmlFor="name" className="form-label">
                        Description
                      </label>
                      <JoditEditor
                        ref={editorRef}
                        value={description}
                        onChange={(content) => setDescription(content)}
                      />
                    </div>
                    <div className="mb-3" style={{ color: "red" }}>
                      {descriptionError}
                    </div>
                    <div className="col-sm-12 mb-3">
                      <label htmlFor="name" className="form-label">
                        Image
                      </label>
                      <SingleImageDropzoneComponent
                        image={files}
                        setImage={setFiles}
                      />
                    </div>
                  </div>
                </form>
                <div>
                  <button
                    type="submit"
                    onClick={handleAddBrand}
                    className="btn btn-primary float-end"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  )
}
