import React, { useState, useEffect, useCallback } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import URL from "../../configuration/config";
import Loading from "../Elements/Loading";
import { AiOutlineSearch } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { nonImageURL, productImageURL } from "../../configuration/domainURL";
import DeleteModal from "../Elements/DeleteModal";

export default function FeatureItem() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [delShow, setDelShow] = useState(false);
  const [delID, setDelID] = useState(null);
  const [productData, setProductData] = useState([]);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [featuredProduct, setFeaturedProduct] = useState([]);
  const [product, setProduct] = useState("");
  const [productTotal, setProductTotal] = useState("");
  const [optionValue, setOptionValue] = useState("DEFAULT");
  const [error, setError] = useState("");
  const [filteredItem, setFilteredItem] = useState([]);
  const [productItem, setProductItem] = useState([]);

  const getAllProduct = useCallback(() => {
    URL.get("/products", {
      params: {
        limit: productTotal,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data.products;
          setProductTotal(response.data.data.totalRows);
          const result = data.filter(
            (element) => !featuredProduct.some((item) => item.id === element.id)
          );
          setProductItem(result);
          setProductData(data);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [productTotal, setProductData, setProductItem, setProductTotal]);

  const getFeaturedProduct = useCallback(
    (page, rowsPerPage) => {
      setLoading(true);
      URL.get("/product/all-featured-products", {
        params: {
          limit: rowsPerPage,
          page: page,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.data.products;
            setFeaturedProduct(data);
            setRows(response.data.data.totalRows);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        })
        .finally(() => {
          getAllProduct();
        });
    },
    [setFeaturedProduct, setRows, getAllProduct]
  );

  useEffect(() => {
    getFeaturedProduct(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage, productTotal, getFeaturedProduct]);

  const Validation = () => {
    let error = "";
    let isValid = true;
    if (optionValue === "DEFAULT") {
      error = "Please select an product";
      isValid = false;
    }

    setError(error);
    return isValid;
  };

  //columns of table
  const columns = [
    {
      name: "Product Image",
      selector: (row) => {
        return (
          <div>
            {row.image === "no-image.png" ? (
              <img
                src={nonImageURL}
                width="30"
                height="30"
                style={{ borderRadius: "50%" }}
                alt="productImg"
              />
            ) : (
              <img
                src={productImageURL + row.image}
                width="30"
                height="30"
                style={{ borderRadius: "50%" }}
                alt="productImg"
              />
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => {
        return (
          <div>
            {row.category === null ? (
              <p>N/A</p>
            ) : (
              row.category.name || row.category_id
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Brand",
      selector: (row) => {
        return <div>{row.brand === null ? <p>N/A</p> : row.brand.name}</div>;
      },
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Product Cost",
      selector: (row) => row.cost,
      sortable: true,
    },

    {
      name: "Product Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <div className="form-check form-switch mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="formSwitch1"
              checked={row.status}
              onChange={() => handleStatus(row.id)}
            />
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <Link to={"/shop/" + row.id}>
              <AiOutlineEye size={24} className="mx-2 viewIcon" />
            </Link>
            <MdOutlineDeleteOutline
              size={24}
              color="red"
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(row.id)}
            />
          </div>
        );
      },
      sortable: true,
      minWidth: 500,
    },
  ];

  const handleStatus = (ID) => {
    // setLoading(true);
    // const statusCopy = [...productData];
    // const index = productData.findIndex((product) => product.id === ID);
    // if (index >= 0) {
    //   URL.post("/products/" + ID + "/status", {
    //     status: !statusCopy[index].status,
    //   })
    //     .then((response) => {
    //       if (response.status === 200) {
    //         statusCopy[index].status = response.data.data.product.status;
    //         setFeaturedProduct(statusCopy);
    //         toast.success(response.data.message);
    //       }
    //     })
    //     .catch((error) => {
    //       setLoading(false);
    //       console.log(error);
    //     });
    // }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
  };

  useEffect(() => {
    setFilteredItem(
      featuredProduct.filter((row) => {
        // return console.log(row.name);
        return row.name.toLowerCase().includes(search.toLowerCase());
      })
    );
  }, [search, featuredProduct]);

  //for deleting products
  const handleDelete = (ID) => {
    setDelID(ID);
    setDelShow(true);
  };

  const handleRemove = () => {
    setLoading(true);
    const featuredproductAfterDel = featuredProduct.filter(
      (featuredProduct) => featuredProduct.id !== delID
    );
    const productToRemove = featuredProduct.find(
      (featuredProduct) => featuredProduct.id === delID
    );
    const formData = new FormData();
    formData.append("product_id", delID);
    URL.post("/product/remove-from-featured", formData)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setFeaturedProduct(featuredproductAfterDel);
          setDelShow(false);
          toast.success("Featured Product Removed Successfully");
          setProductItem([...productItem, productToRemove]);
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!featuredProduct.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    getFeaturedProduct(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    getFeaturedProduct(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      /* A function that returns nothing. */
      return;
    }
    setShow(false);
    setDelShow(false);
  };

  const handleAdd = () => {
    setShow(true);
  };

  const handleProduct = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    setProduct(option);
    const optionName = el.getAttribute("name");
    setOptionValue(optionName);
  };

  const handleAddProduct = () => {
    const Valid = Validation();
    if (Valid) {
      setLoading(true);
      const formData = new FormData();
      formData.append("product_id", product);

      URL.post("/product/add-to-featured", formData)
        .then((response) => {
          console.log(response);
          setLoading(false);
          console.log(response.data.data.product);
          const data = [...featuredProduct, response.data.data.product];
          console.log(data);
          setFeaturedProduct(data);
          const newProductData = productData.filter(
            (element) => !data.some((item) => item.id === element.id)
          );
          setProductItem(newProductData);

          setOptionValue("DEFAULT");
          setShow(false);
          toast.success("Added Successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-md-6">
                    <h6 className="card-title">Featured Products</h6>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-md-6">
                    <div className="input-group" style={{ width: "15rem" }}>
                      <div className="input-group-text">
                        <AiOutlineSearch />
                      </div>
                      <input
                        type="text"
                        className="form-control mr-3"
                        id="navbarForm"
                        placeholder="Search here..."
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <button
                      className="btn btn-primary float-end"
                      onClick={handleAdd}
                    >
                      Add Product
                    </button>
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={filteredItem}
                    defaultSortFieldId
                    pagination
                    paginationServer
                    paginationTotalRows={rows}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={rowsPerPage}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="500px"
                  />
                  {/* Add Modal */}
                  <Modal
                    show={show}
                    centered
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Add Featured Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form>
                        <div>
                          <label className="form-label">Select Product</label>
                          <select
                            className="form-select"
                            id="exampleFormControlSelect1"
                            onChange={handleProduct}
                            defaultValue={"DEFAULT"}
                          >
                            <option value={optionValue} disabled>
                              Select Product
                            </option>
                            {productItem.map((item, i) => {
                              return (
                                <option value={item.name} key={i} id={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                          <div className="mb-3" style={{ color: "red" }}>
                            {error}
                          </div>
                        </div>
                      </form>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleAddProduct}
                      >
                        Add
                      </button>
                    </Modal.Footer>
                  </Modal>
                  {/*Delete Modal */}
                  <DeleteModal
                    modalTitle={"Featured Product"}
                    delShow={delShow}
                    handleClose={handleClose}
                    handleRemove={handleRemove}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
