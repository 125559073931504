import React from "react";
import SliderComponent from "../components/Slider/SliderComponent";

const Slider = () => {
  return (
    <div className="page-content">
      <SliderComponent />
    </div>
  );
};

export default Slider;
