import React, { useState } from "react";
import Loading from "../Elements/Loading";
import PasswordChange from "./PasswordChange";
import ProfileDetails from "./ProfileDetails";

export default function ProfilePage() {
  const [loading, setLoading] = useState(false);
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-6">
            <PasswordChange setLoading={setLoading} />
          </div>
          <div className="col-md-6">
            <ProfileDetails setLoading={setLoading} />
          </div>
        </div>
      )}
    </div>
  );
}
