import React, { useState, useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../Elements/Loading";
import URL from "../../../configuration/config";
import { IconPicker } from "react-fa-icon-picker";

export default function FooterSection5() {
  const [addElement, setAddElement] = useState(false);
  const [footerElements, setFooterElements] = useState({});
  const [data, setData] = useState([]);
  const name = "Footer Section #5";
  const [footerSection5, setFooterSection5] = useState({
    title: "",
    footerLink: [],
  });
  const footerLinkSection = {};
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getFooterElements = () => {
      setLoading(true);
      URL.get("/footer-payload-system/all")
        .then((response) => {
          setLoading(false);
          const footerData = response.data.data.footer_payload_system;
          setFooterElements(footerData);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getFooterElements();
  }, []);

  useEffect(() => {
    const getFooterElementsByName = () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      URL.post("/footer-payload-system/get", formData)
        .then((response) => {
          if (response.status === 200) {
            setData(response.data.data.footer_payload_system);
            setFooterSection5(
              JSON.parse(response.data.data.footer_payload_system.payload)
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          const { status } = error.response;
          if (status === 404) {
            setData(null);
          }
        });
    };

    for (let i = 0; i < footerElements.length; i++) {
      const item = footerElements[i];
      if (item.name === name) {
        getFooterElementsByName();
      }
    }
  }, [footerElements, setData]);

  const handleChange = (section, e, index) => {
    // Add a conditional check to ensure that e is an event object with a target property
    const updatedLinks = [...footerSection5.footerLink];
    const updatedLinkSection = {
      ...updatedLinks[index].footerLinkSection,
      [section]: e,
    };
    updatedLinks[index].footerLinkSection = updatedLinkSection; // Update the current link section object in the array
    setFooterSection5({
      ...footerSection5,
      footerLink: updatedLinks,
    });
  };

  const handleAddFirst = (e) => {
    setAddElement(true);
    const newFooterLink = [
      ...footerSection5.footerLink,
      {
        footerLinkSection,
      },
    ];
    setFooterSection5({ ...footerSection5, footerLink: newFooterLink });
  };
  const handleTitleChange = (event) => {
    const newTitle = event.target.value;
    setFooterSection5({ ...footerSection5, title: newTitle });
  };

  const handleAddFooterSection = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("payload", JSON.stringify(footerSection5));

    URL.post("/footer-payload-system/add-new", formData)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.data.footer_payload_system);
          setLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditFooterSection = (footerData) => {
    setLoading(true);
    const footerUpdate = [...footerElements];
    const index = footerElements.findIndex((data) => data.id === footerData.id);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("payload", JSON.stringify(footerSection5));
    formData.append("_method", "PUT");
    if (index >= 0) {
      footerUpdate[index].name = name;
      footerUpdate[index].payload = JSON.stringify(footerSection5);
      URL.post("/footer-payload-system/update", formData)
        .then((response) => {
          setData(response.data.data.footer_payload_system);
          setLoading(false);
          toast.success(response.data.message);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  return (
    <div>
      {data.length === 0 ? (
        <div>
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              {name}
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse "
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {loading ? (
                <Loading />
              ) : (
                <div>
                  <div className="mb-2">
                    <label htmlFor="name" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Title"
                      required
                      value={footerSection5.title}
                      onChange={handleTitleChange}
                    />
                  </div>
                  <div>
                    <div
                      title="Add Footer Elements"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      onClick={handleAddFirst}
                      className="text-end"
                    >
                      <AiOutlinePlusCircle
                        size={18}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    {addElement && (
                      <>
                        {footerSection5.footerLink.map((item, i) => {
                          const sectionNameFieldName = `sectionName`;
                          const sectionLinkFieldName = `sectionLink`;
                          return (
                            <div className="row" key={i}>
                              <div className="col-md-2 mb-2">
                                <label htmlFor="name" className="form-label">
                                  Icon
                                </label>
                                <div className="icon-picker-textfield">
                                  <IconPicker
                                    color="blue"
                                    name={sectionNameFieldName}
                                    // onChange={(v) => setValue(v)}
                                    value={item.footerLinkSection.sectionName}
                                    onChange={(e) =>
                                      handleChange(sectionNameFieldName, e, i)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-10 mb-2">
                                <label htmlFor="name" className="form-label">
                                  Link
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="title"
                                  placeholder="Link"
                                  name={sectionLinkFieldName}
                                  required
                                  value={footerLinkSection.sectionLink}
                                  onChange={(e) =>
                                    handleChange(
                                      sectionLinkFieldName,
                                      e.target.value,
                                      i
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                  <div className="text-end mt-3">
                    <button
                      type="button"
                      className="btn btn-primary px-5"
                      onClick={handleAddFooterSection}
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              {name}
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse "
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {loading ? (
                <Loading />
              ) : (
                <div>
                  <div className="mb-2">
                    <label htmlFor="name" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Title"
                      required
                      value={footerSection5.title}
                      onChange={handleTitleChange}
                    />
                  </div>
                  <div>
                    <div
                      title="Add Footer Elements"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      onClick={handleAddFirst}
                      className="text-end"
                    >
                      <AiOutlinePlusCircle
                        size={18}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    {/* {addElement && (
                  <> */}
                    {footerSection5.footerLink.map((item, i) => {
                      const sectionNameFieldName = `sectionName`;
                      const sectionLinkFieldName = `sectionLink`;
                      return (
                        <div className="row" key={i}>
                          <div className="col-md-2 mb-2">
                            <label htmlFor="name" className="form-label">
                              Icon
                            </label>
                            <div className="icon-picker-textfield">
                              <IconPicker
                                color="blue"
                                name={sectionNameFieldName}
                                // onChange={(v) => setValue(v)}
                                value={item.footerLinkSection.sectionName}
                                onChange={(e) =>
                                  handleChange(sectionNameFieldName, e, i)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-10 mb-2">
                            <label htmlFor="name" className="form-label">
                              Link
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="title"
                              placeholder="Link"
                              name={sectionLinkFieldName}
                              required
                              value={item.footerLinkSection.sectionLink}
                              onChange={(e) =>
                                handleChange(
                                  sectionLinkFieldName,
                                  e.target.value,
                                  i
                                )
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                    {/* </>
                )} */}
                  </div>
                  <div className="text-end mt-3">
                    <button
                      type="button"
                      className="btn btn-primary px-5"
                      onClick={() => handleEditFooterSection(data)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
