import React from "react";
import PrivacyPolicy from "../components/Privacy/PrivacyPolicy";

export default function Privacy() {
  return (
    <div className="page-content">
      <PrivacyPolicy />
    </div>
  );
}
