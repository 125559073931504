import React, { useState, useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
// import { MdOutlineDeleteOutline } from "react-icons/md";
import { FiAlertTriangle } from "react-icons/fi";
import Modal from "react-bootstrap/Modal";
import URL from "../../configuration/config";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../Elements/Loading";

export default function StatesSection({ name3 }) {
  const [delStateShow, setDelStateShow] = useState(false);
  const [addElement, setAddElement] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statesSection, setStatesSection] = useState({
    stateItem: [],
  });
  const stateItemSection = {};
  const [stateElements, setStatesElements] = useState([]);
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    const getStatesSection = () => {
      setLoading(true);
      URL.get("/cms-payload-system/all")
        .then((response) => {
          setLoading(false);
          const data = response.data.data.cms_payload_system;
          setStatesElements(data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    getStatesSection();
  }, []);

  useEffect(() => {
    const getStatesElementsByName = () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name3);
      URL.post("/cms-payload-system/get", formData)
        .then((response) => {
          if (response.status === 200) {
            setStateData(response.data.data.cms_payload_system);
            setStatesSection(
              JSON.parse(response.data.data.cms_payload_system.payload)
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    for (let i = 0; i < stateElements.length; i++) {
      const item = stateElements[i];
      if (item.name === name3) {
        getStatesElementsByName();
      }
    }
  }, [stateElements, name3]);

  const handleAddState = () => {
    setAddElement(true);
    const newLink = [
      ...statesSection.stateItem,
      {
        stateItemSection,
      },
    ];
    setStatesSection({ ...statesSection, stateItem: newLink });
  };

  // const handleDeleteState = () => {
  //   setDelStateShow(true);
  // };

  const Validation = () => {
    let error = "";
    let isValid = true;
    for (let i = 0; i < statesSection.stateItem.length; i++) {
      const stateItemSection = statesSection.stateItem[i].stateItemSection;
      if (Object.keys(stateItemSection).length === 0) {
        error = "Please up the form";
        toast.error(error);
        isValid = false;
      }
    }
    return isValid;
  };

  const handleChange = (e, index) => {
    const updatedLinks = [...statesSection.stateItem];
    const updatedLinkSection = {
      ...updatedLinks[index].stateItemSection, // Get the current section data for the current index
      [e.target.name]: e.target.value,
    };
    const updatedLink = {
      stateItemSection: updatedLinkSection,
    };
    updatedLinks[index] = updatedLink; // Update the current array element with the updated link object
    setStatesSection({
      ...statesSection,
      stateItem: updatedLinks,
    });
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDelStateShow(false);
  };

  const handleAdd = () => {
    const Valid = Validation();
    if (Valid) {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name3);
      formData.append("payload", JSON.stringify(statesSection));

      URL.post("/cms-payload-system/add-new", formData)
        .then((response) => {
          if (response.status === 200) {
            setStatesSection(response.data.data.cms_payload_system);
            setLoading(false);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const handleEdit = (stateDate) => {
    setLoading(true);
    const stateUpdate = [...stateElements];
    const index = stateElements.findIndex((data) => data.id === stateDate.id);
    const formData = new FormData();
    formData.append("name", name3);
    formData.append("payload", JSON.stringify(statesSection));
    formData.append("_method", "PUT");
    if (index >= 0) {
      stateUpdate[index].name = name3;
      stateUpdate[index].payload = JSON.stringify(statesSection);
      URL.post("/cms-payload-system/update", formData)
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  console.log(statesSection);
  return (
    <div>
      {/* States */}
      {loading ? (
        <Loading />
      ) : (
        <div>
          {stateData.length === 0 ? (
            <div className="mb-5">
              <div className="d-flex justify-content-between mb-3">
                <h6>Add States</h6>
                <div onClick={handleAddState}>
                  <AiOutlinePlusCircle
                    size={24}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Number</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {addElement && (
                    <>
                      {statesSection.stateItem.map((item, i) => {
                        const titleField = `title`;
                        const numberField = `number`;
                        return (
                          <tr key={i}>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Title"
                                name={titleField}
                                value={item.stateItemSection.title}
                                onChange={(e) => handleChange(e, i)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Number"
                                name={numberField}
                                value={item.stateItemSection.number}
                                onChange={(e) => handleChange(e, i)}
                              />
                            </td>
                            {/* <td>
                              <div className="float-end">
                                <MdOutlineDeleteOutline
                                  size={24}
                                  color="red"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleDeleteState}
                                />
                              </div>
                            </td> */}
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>
              <div className="text-end mt-4">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAdd}
                >
                  Add
                </button>
              </div>
            </div>
          ) : (
            <div className="mb-5">
              <div className="d-flex justify-content-between mb-3">
                <h6>Add States</h6>
                <div onClick={handleAddState}>
                  <AiOutlinePlusCircle
                    size={24}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Number</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {statesSection.stateItem.map((item, i) => {
                    const titleField = `title`;
                    const numberField = `number`;
                    return (
                      <tr key={i}>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Title"
                            name={titleField}
                            value={item.stateItemSection.title}
                            onChange={(e) => handleChange(e, i)}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Number"
                            name={numberField}
                            value={item.stateItemSection.number}
                            onChange={(e) => handleChange(e, i)}
                          />
                        </td>
                        {/* <td>
                          <div className="float-end">
                            <MdOutlineDeleteOutline
                              size={24}
                              color="red"
                              style={{ cursor: "pointer" }}
                              onClick={handleDeleteState}
                            />
                          </div>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="text-end mt-4">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleEdit(stateData)}
                >
                  Edit
                </button>
              </div>
            </div>
          )}

          {/*Delete States  */}
          <Modal
            show={delStateShow}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Feature Section</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="icontext">
                <FiAlertTriangle className="alert_icon py-2" />
                <h4>Do you really want to delete it?</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                // onClick={handleRemove}
              >
                Delete
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
