import React from "react";
import Modal from "react-bootstrap/Modal";
import { FiAlertTriangle } from "react-icons/fi";

export default function DeleteModal({
  modalTitle,
  delShow,
  handleClose,
  handleRemove,
}) {
  return (
    <div>
      <Modal
        show={delShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete {modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="icontext">
            <FiAlertTriangle className="alert_icon py-2" />
            <h4>Do you really want to delete it?</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleRemove}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
