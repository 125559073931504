import React, { useState } from "react";
import { toast } from "react-toastify";
import URL from "../../configuration/config";
import { useLoginDetailsTheme } from "../../context/ThemeProvider";

export default function ProfileDetails() {
  const [nameError, setNameError] = useState("");
  const { name, email, setName } = useLoginDetailsTheme();

  const Validation = () => {
    let isValid = true;
    let nameError = "";

    if (!name) {
      nameError = "This field is required";
      isValid = false;
    }

    setNameError(nameError);
    return isValid;
  };

  const handleProfileEdit = () => {
    const Valid = Validation();
    if (Valid) {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("_method", "PUT");

      URL.post("/profile/update-profile", formData)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            toast.success(response.data.message);
            localStorage.setItem("name", name);
          }
        })
        .catch((error) => {
          const { status, data } = error.response;
          if (status === 404) {
            toast.error(data.message);
          }
        });
    }
  };
  return (
    <div>
      <div className="card">
        <h4 className="pt-4 px-4">Admin's Details</h4>
        <hr className="mx-4" />
        <div className="py-4 px-4">
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              value={email}
              disabled
              required
            />
          </div>
          <div className="mb-3" style={{ color: "red" }}>
            {nameError}
          </div>
          <button className="btn btn-primary" onClick={handleProfileEdit}>
            Edit
          </button>
        </div>
      </div>
    </div>
  );
}
