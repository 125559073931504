import React, { useState } from "react";
import FooterSection1 from "./AccordianElements/FooterSection1";
import FooterSection3 from "./AccordianElements/FooterSection3";
import FooterSection2 from "./AccordianElements/FooterSection2";
import FooterSection4 from "./AccordianElements/FooterSection4";
import FooterSection5 from "./AccordianElements/FooterSection5";

export default function FooterElement() {
  return (
    <div>
      <div className="row">
        <div className="col-md-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex flex-row justify-content-between">
                <div>
                  <h6 className="card-title page-title">Footer Section</h6>
                </div>
              </div>
              <div className="card p-4 mb-3">
                <div className="accordion" id="accordionExample">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="accordion-item mb-2">
                        <FooterSection1 />
                      </div>
                      <div className="accordion-item mb-2">
                        <FooterSection3 />
                      </div>
                      <div className="accordion-item mb-2">
                        <FooterSection5 />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="accordion-item mb-2">
                        <FooterSection2 />
                      </div>
                      <div className="accordion-item mb-2">
                        <FooterSection4 />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row gx-4">
                  <div className="col-lg-3 my-3">
                    <div className="about-card">
                      <div className="d-flex justify-content-between">
                        <h5 className="card-about-title">About</h5>
                        <div>
                          <FaEdit
                            size={15}
                            className="me-2"
                            style={{ cursor: "pointer" }}
                            onClick={handleEdit}
                          />
                        </div>
                      </div>
                      <div className="card logo-card">
                        <img
                          src="/assets/images/pictures/logo1.png"
                          alt="logo"
                        />
                      </div>
                      <p className="my-2 logo-card-title">
                        Praesent dapibus, neque id cursus ucibus, tortor neque
                        egestas augue, eu vulputate magna eros eu erat. Aliquam
                        erat volutpat. Nam dui mi, tincidunt quis, accumsan
                        porttitor, facilisis luctus, metus.
                      </p>
                      <p className="my-2">Number: +0123 456 789</p>
                      <p className="my-2">Payment Method :</p>
                    </div>
                  </div>
                  <div className="col-lg-3 my-3">
                    <PageSection />
                  </div>
                  <div className="col-lg-3 my-3">
                    <InfoSection />
                  </div>
                  <div className="col-lg-3 my-3">
                    <LegalInfo />
                  </div>
                </div> */}
              </div>
              {/* <div className="card p-4">
                <LowerFooterSection />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
