import React from "react";
import ProductForm from "../components/Product/ProductForm";

export default function Product() {
  return (
    <div className="page-content">
      <ProductForm />
    </div>
  );
}
