import React from "react";
import TopHeader from "../components/Dashboard/TopHeader";
// import Charts from "../components/Dashboard/Charts";
import OrderPreview from "../components/Dashboard/OrderPreview";

export default function Dashboard() {
  return (
    <div className="page-content">
      <TopHeader />
      {/* <Charts /> */}
      <OrderPreview />
    </div>
  );
}
