import React, { useState, useEffect, useRef } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { useParams } from "react-router-dom";
import URL from "../../configuration/config";
import moment from "moment";
import Loading from "../Elements/Loading";
import { useReactToPrint } from "react-to-print";

import PrintButton from "./PrintButton";
const OrderInvoice = () => {
  const { id } = useParams();
  const [singleOrderDetail, setSingleOrderDetail] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [billingDetails, setBillingDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState([]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const orderId = parseInt(id);
  const names = ["company_name", "city", "street_address"];

  useEffect(() => {
    const getOrderDetail = () => {
      setLoading(true);
      URL.get("/orders/all")
        .then((response) => {
          setLoading(false);
          const data = response.data.data.orders;
          const OrderedproductDetails = data.find(
            (detail) => detail.id === orderId
          );
          setSingleOrderDetail(OrderedproductDetails);
          setOrderItems(OrderedproductDetails.orderitems);
          setBillingDetails(OrderedproductDetails.customer.billing);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getOrderDetail();
  }, [orderId]);

  useEffect(() => {
    const getCompanyDetails = () => {
      const formData = new FormData();
      names.forEach((name) => {
        formData.append("names[]", name);
      });
      URL.post("/system-settings/get", formData)
        .then((response) => {
          if (response.status === 200) {
            setCompanyDetails(response.data.data.system_settings);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getCompanyDetails();
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div ref={componentRef} id="pagetodownload">
                  <div className="container-fluid d-flex justify-content-between">
                    <div className="col-lg-3 ps-0">
                      <a href="/" className="noble-ui-logo d-block mt-3 mb-3">
                        <img
                          src="/assets/images/pictures/logo.png"
                          width={100}
                          height={50}
                          alt="logo"
                        />
                      </a>
                      {companyDetails.map((item, index) => {
                        return (
                          <div key={index}>
                            <p>
                              {item.name === "company_name" ? (
                                <span>{item.settings},</span>
                              ) : null}
                            </p>
                            {item.name === "street_address" ? (
                              <p>
                                <span>{item.settings},</span>
                                {
                                  companyDetails.find(
                                    (detail) => detail.name === "city"
                                  )?.settings
                                }
                              </p>
                            ) : null}
                          </div>
                        );
                      })}
                      <h5 className="mt-5 mb-2 text-muted">Invoice to :</h5>
                      <p>
                        {billingDetails.first_name +
                          " " +
                          billingDetails.last_name}
                        <br /> {billingDetails.address_1},
                        <br /> {billingDetails.city},
                        {billingDetails.landmark === "null"
                          ? ""
                          : +billingDetails.landmark}
                      </p>
                    </div>
                    <div className="col-lg-3 pe-0">
                      <h4 className="fw-bolder text-uppercase text-end mt-4 mb-2">
                        invoice_ref
                      </h4>
                      <h6 className="text-end mb-5 pb-4">
                        # {singleOrderDetail.ref}
                      </h6>
                      <p className="text-end mb-1">Balance Due</p>
                      <h4 className="text-end fw-normal">
                        Rs {singleOrderDetail.grand_total}
                      </h4>
                      <h6 className="mb-0 mt-3 text-end fw-normal mb-2">
                        <span className="text-muted">Invoice Date :</span>{" "}
                        {moment(singleOrderDetail.created_at).format(
                          "YYYY-MM-DD"
                        )}
                      </h6>
                    </div>
                  </div>
                  <div className="container-fluid mt-5 d-flex justify-content-center w-100">
                    <div className="table-responsive w-100">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th className="text-end">Net Unit Price</th>
                            <th className="text-end">Quantity</th>
                            <th className="text-end">Unit Price</th>
                            <th className="text-end">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderItems.map((item, index) => {
                            return (
                              <tr className="text-end" key={index}>
                                <td className="text-start">
                                  {item.product.name}
                                </td>
                                <td>Rs {item.product.price}</td>
                                <td>{item.quantity}</td>
                                <td>Rs {item.product.price}</td>
                                <td>Rs{item.quantity * item.product.price}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="container-fluid mt-5 w-100">
                    <div className="row">
                      <div className="col-md-6 ms-auto">
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>Total</td>
                                <td className="text-end">
                                  Rs {singleOrderDetail.sub_total}
                                </td>
                              </tr>
                              <tr>
                                <td>Shipping</td>
                                <td className="text-end">Rs 0</td>
                              </tr>
                              <tr>
                                <td className="text-bold-800">Grand Total</td>
                                <td className="text-bold-800 text-end">
                                  Rs {singleOrderDetail.grand_total}
                                </td>
                              </tr>
                              <tr>
                                <td>Payment Made</td>
                                <td className="text-danger text-end">
                                  (-) Rs {singleOrderDetail.paid}
                                </td>
                              </tr>
                              <tr className="bg-light">
                                <td className="text-bold-800">Due</td>
                                <td className="text-bold-800 text-end">
                                  Rs {singleOrderDetail.due}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid w-100">
                  <PrintButton
                    rootElementId="pagetodownload"
                    downloadFileName={
                      billingDetails.first_name + "_" + singleOrderDetail.ref
                    }
                  />
                  <button
                    className="btn btn-outline-primary btn-icon-text float-end mt-3 mx-3"
                    onClick={handlePrint}
                  >
                    <AiFillPrinter />
                    Print
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderInvoice;
