import React from "react";
import ViewBrands from "../components/Brand/ViewBrands";

export default function AllBrands() {
  return (
    <div className="page-content">
      <ViewBrands />
    </div>
  );
}
