import React from "react";
import ViewCategroy from "../components/Category/ViewCategroy";

export default function AllCategory() {
  return (
    <div className="page-content">
      <ViewCategroy />
    </div>
  );
}
