import React from "react";
import ErrorMessage from "../components/Error/ErrorMessage";

export default function Error() {
  return (
    <div>
      <ErrorMessage />
    </div>
  );
}
