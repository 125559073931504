import React from "react";
import AboutForm from "../components/About/AboutForm";

export default function About() {
  return (
    <div className="page-content">
      <AboutForm />
    </div>
  );
}
