import React from "react"
import "./App.css"
import Dashboard from "./pages/Dashboard"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Category from "./pages/Category"
import AllCategory from "./pages/AllCategory"
import Product from "./pages/Product"
import AllProduct from "./pages/AllProduct"
// import Blog from "./pages/Blog";
// import AllBlog from "./pages/AllBlog";
import Orders from "./pages/Orders"
import Login from "./pages/Login"
import About from "./pages/About"
import Faq from "./pages/Faq"
import Terms from "./pages/Terms"
import Privacy from "./pages/Privacy"
import LoginLayout from "./layout/LoginLayout"
import Brand from "./pages/Brand"
import AllBrands from "./pages/AllBrands"
import Error from "./pages/Error"
import PrivateRoutes from "./pages/PrivateRoutes"
import SingleProduct from "./pages/SingleProduct"
import Users from "./pages/Users"
import OrderDetail from "./pages/OrderDetail"
import { ThemeProvider } from "./context/ThemeProvider"
import "./Theme"
import Profile from "./pages/Profile"
import RefundPolicy from "./pages/RefundPolicy"
import ReturnPolicy from "./pages/ReturnPolicy"
import SystemSettings from "./pages/SystemSettings"
import FeaturedProduct from "./pages/FeaturedProduct"
import Slider from "./pages/Slider"
import Contact from "./pages/Contact"
import Banner from "./pages/Banner"
import Footer from "./pages/Footer"
import SingleContact from "./pages/SingleContact"
import Menu from "./layout/Menu"
import Header from "./pages/Header"
import Register from "./pages/Register"
import { ToastContainer } from "react-toastify"

function App() {
  return (
    <div>
      <ThemeProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LoginLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<Menu />}>
                <Route index element={<Dashboard />} />
                <Route path="/order" element={<Orders />} />
                <Route path="/category" element={<Category />} />
                <Route path="/categories" element={<AllCategory />} />
                <Route path="/product" element={<Product />} />
                <Route path="/shop" element={<AllProduct />} />
                {/* <Route path="/blog" element={<Blog />} /> */}
                {/* <Route path="/posts" element={<AllBlog />} /> */}
                <Route path="/about" element={<About />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/slider" element={<Slider />} />
                <Route path="/brand" element={<Brand />} />
                <Route path="/labels" element={<AllBrands />} />
                <Route path="/shop/:id" element={<SingleProduct />} />
                <Route path="/users" element={<Users />} />
                <Route path="/order/:id" element={<OrderDetail />} />
                <Route path="/systemsettings" element={<SystemSettings />} />
                <Route path="/refund" element={<RefundPolicy />} />
                <Route path="/return" element={<ReturnPolicy />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/featuredProducts" element={<FeaturedProduct />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/banner" element={<Banner />} />
                <Route path="/footer" element={<Footer />} />
                <Route path="/contact/:id" element={<SingleContact />} />
                <Route path="/header" element={<Header />} />
              </Route>
              <Route path="*" element={<Error />} />
            </Route>
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  )
}

export default App
