// react imports
import { useCallback, useMemo } from "react"
// third party imports
import { useDropzone } from "react-dropzone"
import { AiOutlineDelete } from "react-icons/ai"
import { toast } from "react-toastify"

// styles
const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  // borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  transition: "border .3s ease-in-out"
}
const activeStyle = {
  borderColor: "#2196f3"
}
const rejectStyle = {
  borderColor: "red"
}

// custome validator
function maxSizeValidator(image) {
  if (image.size > 1 * 1027 * 1027) {
    //-- Check if the image file size is less than 1MB
    // ** This number is slightly bigger than the 1024*1024 due to windows 1 mb size being 1054294 bytes
    toast.error("Image size should not exceed 1 MB", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
    return {
      code: "size-too-large",
      message: "File size is larger than 1 MB"
    } //-- Do not proceed further if the file size is too large
  }

  return null
}

const toMb = (bytes) => {
  return (bytes / (1024 * 1024)).toFixed(2)
}

// Single image dropzone component
export function SingleImageDropzoneComponent({ image, setImage, imgRef }) {
  // do something with the image when it is added
  const onDrop = useCallback(
    (acceptedImage) => {
      setImage(
        acceptedImage.map((file, i) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
    },
    [setImage]
  )

  // react dropzone configs
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections
  } = useDropzone({
    validator: maxSizeValidator,
    onDrop,
    maxFiles: 1,
    accept: { "image/*": [".jpeg", ".png", ".jpg"] }
  })

  // styling for unaccepted files when drag&drop
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject]
  )

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div key={errors}>
      {errors.map((e) => (
        <div className="mb-1" key={e.code} style={{ color: "red" }}>
          {e.message}
          <li key={file.path}>
            {file.path} is {toMb(file.size)} MB
          </li>
        </div>
      ))}
    </div>
  ))

  const deleteFile = (e) => {
    const s = image.filter((item, index) => index !== e)
    setImage(s)
  }

  return (
    <section>
      <div>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} multiple={false} />
          <div>Drag and drop your images here.</div>
          <div>Image should be less than 1 MB in size</div>
        </div>
        {/* Image Preview area */}
        <div className="row">
          {image?.map((item, index) => {
            return (
              <div className="col-sm-2 mt-2" key={index}>
                <div className="card">
                  <img
                    className="card-edit-image"
                    src={item.preview}
                    alt={item.name}
                    ref={imgRef}
                  />
                  <div className="row">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6">
                      <AiOutlineDelete
                        type="button"
                        onClick={() => deleteFile(index)}
                        className="m-1 float-end mx-2"
                        style={{
                          fontSize: "20px",
                          color: "red"
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="d-flex flex-column">{fileRejectionItems}</div>
      </div>
    </section>
  )
}

// Multiple images dropzone component
export function MultiImageDropzoneComponent({
  images,
  setImages,
  imgRef,
  maxFile
}) {
  // do something with the files when they are added
  const onDrop = useCallback(
    (acceptedImages) => {
      setImages(
        // creating a url preview of the image
        acceptedImages.map((file, i) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
    },
    [setImages]
  )

  // react dropzone configs
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections
  } = useDropzone({
    validator: maxSizeValidator,
    onDrop,
    maxFiles: 7,
    accept: { "image/*": [".jpeg", ".png", ".jpg"] }
  })

  // styling for unaccepted files when drag&drop
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject]
  )

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div key={errors}>
      {errors.map((e) => (
        <div className="mb-1" key={e.code} style={{ color: "red" }}>
          {e.message}
          <li key={file.path}>
            {file.path} is {toMb(file.size)} MB
          </li>
        </div>
      ))}
    </div>
  ))

  const deleteFile = (e) => {
    const s = images.filter((item, index) => index !== e)
    setImages(s)
  }

  const isDisabled = images.length >= maxFile

  return (
    <section>
      <div>
        {/* Image Add area */}
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} multiple={true} disabled={isDisabled} />
          <div>Drag and drop your images here.</div>
          <div>Images should be less than 1 MB in size</div>
        </div>
        {/* Image Preview area */}
        <div className="row">
          {images?.map((item, index) => {
            return (
              <div className="col-sm-2 mt-2" key={index}>
                <div className="card">
                  <img
                    className="card-edit-image"
                    src={item.preview}
                    alt={item.name}
                    ref={imgRef}
                  />
                  <div className="row">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6">
                      <AiOutlineDelete
                        type="button"
                        onClick={() => deleteFile(index)}
                        className="m-1 float-end mx-2"
                        style={{
                          fontSize: "20px",
                          color: "red"
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {/* File Rejection Messages */}
        <div className="d-flex flex-column">{fileRejectionItems}</div>
      </div>
    </section>
  )
}

export default SingleImageDropzoneComponent
