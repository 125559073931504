import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import URL from "../../configuration/config";
import { AiOutlineSearch } from "react-icons/ai";
import Loading from "../Elements/Loading";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function ContactInfo() {
  const [loading, setLoading] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [rows, setRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filteredContact, setFilteredContact] = useState([]);

  useEffect(() => {
    getContactList(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const getContactList = (page, rowsPerPage) => {
    setLoading(true);
    URL.get("/contacts", {
      params: {
        limit: rowsPerPage,
        page: page,
      },
    })
      .then((response) => {
        setContactList(response.data.data.contacts);
        setRows(response.data.data.totalRows);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  //columns of table
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row.message,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <Link to={"/contact/" + row.id}>
              <AiOutlineEye
                size={24}
                className="mx-2 viewIcon"
                // onClick={() => handleOpen(row)}
                // style={{ cursor: "pointer" }}
              />
            </Link>
          </div>
        );
      },
      sortable: true,
      minWidth: 500,
    },
  ];

  //for changing the rows of the dataTable
  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!contactList.length) return;
    getContactList(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    getContactList(page, rowsPerPage);
    setCurrentPage(page);
  };

  useEffect(() => {
    setFilteredContact(
      contactList.filter((row) => {
        return row.name.toLowerCase().includes(search.toLowerCase());
      })
    );
  }, [search, contactList]);

  const handleSearch = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-6 mt-1">
                    <h6 className="card-title">Queries List</h6>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group" style={{ width: "35rem" }}>
                      <div className="input-group-text">
                        <AiOutlineSearch />
                      </div>
                      <input
                        type="text"
                        className="form-control float-end"
                        id="navbarForm"
                        placeholder="Search here..."
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={filteredContact}
                    defaultSortFieldId
                    pagination
                    paginationServer
                    paginationTotalRows={rows}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={rowsPerPage}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="500px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
