import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import URL from "../../configuration/config";
import Loading from "../Elements/Loading";
import { ToastContainer, toast } from "react-toastify";

export default function AboutSection({ name1 }) {
  const [aboutSection, setAboutSection] = useState({
    title: "Our Vision",
    mission: "",
    title1: "Our Mission",
    vision: "",
    title2: "Who we are",
    who: "",
    buttonText: "",
    buttonLink: "",
    image1: "",
    image2: "",
  });
  const [loading, setLoading] = useState(false);
  const [aboutElement, setAboutElements] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const editorRef = useRef(null);

  useEffect(() => {
    const getAboutSection = () => {
      setLoading(true);
      URL.get("/cms-payload-system/all")
        .then((response) => {
          setLoading(false);
          const data = response.data.data.cms_payload_system;
          setAboutElements(data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    getAboutSection();
  }, []);

  useEffect(() => {
    const getAboutElementsByName = () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name1);
      URL.post("/cms-payload-system/get", formData)
        .then((response) => {
          if (response.status === 200) {
            setAboutData(response.data.data.cms_payload_system);
            setAboutSection(
              JSON.parse(response.data.data.cms_payload_system.payload)
            );
            setLoading(false);
          }
        })
        .catch((error) => {});
    };
    for (let i = 0; i < aboutElement.length; i++) {
      const item = aboutElement[i];
      if (item.name === name1) {
        getAboutElementsByName();
      }
    }
  }, [aboutElement, name1]);

  const handleChange = (value, name) => {
    setAboutSection({ ...aboutSection, [name]: value });
  };

  const addAbout = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name1);
    formData.append("payload", JSON.stringify(aboutSection));
    URL.post("/cms-payload-system/add-new", formData)
      .then((response) => {
        setAboutData(response.data.data.cms_payload_system);
        setLoading(false);
        toast.success(response.data.message);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleEditAboutSection = (aboutData) => {
    setLoading(true);
    const aboutUpdate = [...aboutElement];
    const index = aboutElement.findIndex((data) => data.id === aboutData.id);
    const formData = new FormData();
    formData.append("name", name1);
    formData.append("payload", JSON.stringify(aboutSection));
    formData.append("_method", "PUT");
    if (index >= 0) {
      aboutUpdate[index].name = name1;
      aboutUpdate[index].payload = JSON.stringify(aboutSection);
      URL.post("/cms-payload-system/update", formData)
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {aboutData.length === 0 ? (
            <div>
              <div className="mb-5">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="name" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Button Link"
                      value={aboutSection.title}
                      onChange={(e) => handleChange(e.target.value, "title")}
                    />{" "}
                  </div>
                </div>
                <label htmlFor="name" className="form-label">
                  Description
                </label>
                <JoditEditor
                  ref={editorRef}
                  value={aboutSection.vision}
                  onBlur={(value) => handleChange(value, "vision")}
                />
              </div>
              <div className="mb-5">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="name" className="form-label">
                      Title 1
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Button Link"
                      value={aboutSection.title1}
                      onChange={(e) => handleChange(e.target.value, "title1")}
                    />{" "}
                  </div>
                </div>
                <JoditEditor
                  ref={editorRef}
                  value={aboutSection.mission}
                  onBlur={(value) => handleChange(value, "mission")}
                />
              </div>
              <div className="mb-5">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="name" className="form-label">
                      Title 2
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Button Link"
                      value={aboutSection.title2}
                      onChange={(e) => handleChange(e.target.value, "title2")}
                    />{" "}
                  </div>
                </div>
                <div className="row gx-5">
                  <div className="col-sm-12 col-lg-6">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="name" className="form-label">
                          Image Link
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Image URL"
                          value={aboutSection.image1}
                          onChange={(e) =>
                            handleChange(e.target.value, "image1")
                          }
                        />
                      </div>
                      {/* <div className="col-md-12">
                        <label htmlFor="name" className="form-label">
                          Image Link
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Image URL"
                          value={aboutSection.image2}
                          onChange={(e) =>
                            handleChange(e.target.value, "image2")
                          }
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="mb-3">
                      <JoditEditor
                        ref={editorRef}
                        value={aboutSection.who}
                        onBlur={(value) => handleChange(value, "who")}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Button Text
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Button Text"
                        value={aboutSection.buttonText}
                        onChange={(e) =>
                          handleChange(e.target.value, "buttonText")
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Button Link
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Button Link"
                        value={aboutSection.buttonLink}
                        onChange={(e) =>
                          handleChange(e.target.value, "buttonLink")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addAbout}
                >
                  Add
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="mb-5">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="name" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Button Link"
                      value={aboutSection.title}
                      onChange={(e) => handleChange(e.target.value, "title")}
                    />{" "}
                  </div>
                </div>
                <label htmlFor="name" className="form-label">
                  Description
                </label>
                <JoditEditor
                  ref={editorRef}
                  value={aboutSection.vision}
                  onBlur={(value) => handleChange(value, "vision")}
                />
              </div>
              <div className="mb-5">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="name" className="form-label">
                      Title 1
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Button Link"
                      value={aboutSection.title1}
                      onChange={(e) => handleChange(e.target.value, "title1")}
                    />{" "}
                  </div>
                </div>
                <JoditEditor
                  ref={editorRef}
                  value={aboutSection.mission}
                  onBlur={(value) => handleChange(value, "mission")}
                />
              </div>
              <div className="mb-5">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="name" className="form-label">
                      Title 2
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Button Link"
                      value={aboutSection.title2}
                      onChange={(e) => handleChange(e.target.value, "title2")}
                    />{" "}
                  </div>
                </div>
                <div className="row gx-5">
                  <div className="col-sm-12 col-lg-6">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="name" className="form-label">
                          Image Link
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Image URL"
                          value={aboutSection.image1}
                          onChange={(e) =>
                            handleChange(e.target.value, "image1")
                          }
                        />
                      </div>
                      {/* <div className="col-md-12">
                        <label htmlFor="name" className="form-label">
                          Image1 Link
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Image URL"
                          value={aboutSection.image2}
                          onChange={(e) =>
                            handleChange(e.target.value, "image2")
                          }
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="mb-3">
                      <JoditEditor
                        ref={editorRef}
                        value={aboutSection.who}
                        onBlur={(value) => handleChange(value, "who")}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Button Text
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Button Text"
                        value={aboutSection.buttonText}
                        onChange={(e) =>
                          handleChange(e.target.value, "buttonText")
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Button Link
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Button Link"
                        value={aboutSection.buttonLink}
                        onChange={(e) =>
                          handleChange(e.target.value, "buttonLink")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleEditAboutSection(aboutData)}
                >
                  Edit
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
