import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import URL from "../../configuration/config";
import { AiOutlineSearch } from "react-icons/ai";
import Loading from "../Elements/Loading";

export default function CustomerDetails() {
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState([]);
  const [rows, setRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getUser(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const getUser = (page, rowsPerPage) => {
    setLoading(true);
    URL.get("/clients", {
      params: {
        limit: rowsPerPage,
        page: page,
      },
    })
      .then((response) => {
        console.log(response);
        setUserDetail(response.data.data.clients);
        console.log(response.data.data.clients);
        setRows(response.data.data.totalRows);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  //columns of table
  const columns = [
    {
      name: "Full Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "First Name",
      selector: (row) => {
        return (
          <div>
            {row.billing === null ? <p>N/A</p> : row.billing.first_name}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => {
        return (
          <div>{row.billing === null ? <p>N/A</p> : row.billing.last_name}</div>
        );
      },
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => {
        return <div>{row.email === null ? <p>N/A</p> : row.email}</div>;
      },
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => {
        return (
          <div>{row.billing === null ? <p>N/A</p> : row.billing.phone}</div>
        );
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => {
        return (
          <div>{row.billing === null ? <p>N/A</p> : row.billing.address_1}</div>
        );
      },
      sortable: true,
    },
    // {
    //   name: "Action",
    //   selector: (row) => {
    //     return (
    //       <div>
    //         <FaEdit
    //           size={22}
    //           color="green"
    //           className="mx-2"
    //           style={{ cursor: "pointer" }}
    //           onClick={() => handleEdit(row)}
    //         />
    //         <MdOutlineDeleteOutline
    //           size={24}
    //           color="red"
    //           className="mx-2"
    //           style={{ cursor: "pointer" }}
    //           onClick={() => handleDelete(row.id)}
    //         />
    //       </div>
    //     );
    //   },
    //   sortable: true,
    //   minWidth: 500,
    // },
  ];

  //for changing the rows of the dataTable
  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!userDetail.length) return;
    getUser(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    getUser(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
    URL.get("/clients", {
      params: {
        search: search,
        limit: rowsPerPage,
        // page: page,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          const data = response.data.data.clients;
          setUserDetail(data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-6 mt-1">
                    <h6 className="card-title">Customer List</h6>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group" style={{ width: "35rem" }}>
                      <div className="input-group-text">
                        <AiOutlineSearch />
                      </div>
                      <input
                        type="text"
                        className="form-control float-end"
                        id="navbarForm"
                        placeholder="Search here..."
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={userDetail}
                    defaultSortFieldId
                    pagination
                    paginationServer
                    paginationTotalRows={rows}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={rowsPerPage}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="500px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
