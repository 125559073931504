import React from "react";

export default function Loading() {
  return (
    <div className="">
      <div
        className="spinner-border text-warning"
        role="status"
        style={{
          padding: "2rem",
          margin: "5rem auto",
          display: "block",
        }}
      >
        <span className="visually-hidden m-auto">Loading...</span>
      </div>
    </div>
  );
}
