import React, { useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FaqData } from "../../data/FaqData";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import JoditEditor from "jodit-react";
import DeleteModal from "../Elements/DeleteModal";

export default function Questions() {
  const editorRef = useRef(null);
  const [answer, setAnswer] = useState("");
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [delShow, setDelShow] = useState(false);
  // const [delID, setDelID] = useState(null);

  //columns of table
  const columns = [
    {
      name: "Category",
      selector: (row) => row.Category,
      sortable: true,
    },
    {
      name: "Question",
      selector: (row) => row.Question,
      sortable: true,
    },
    {
      name: "Answer",
      selector: (row) => row.Answer.slice(0, 50),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <div className="form-check form-switch mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="formSwitch1"
            />
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <FaEdit
              size={22}
              color="green"
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(row)}
            />
            <MdOutlineDeleteOutline
              size={24}
              color="red"
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(row.id)}
            />
          </div>
        );
      },
      sortable: true,
    },
  ];
  function handleAdd() {
    setShow(true);
  }
  function handleEdit(data) {
    setEditShow(true);
  }
  function handleDelete(ID) {
    // setDelID(ID);
    setDelShow(true);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setEditShow(false);
    setShow(false);
    setDelShow(false);
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-6">
                  <h6 className="card-title">Frequently Asked Questions</h6>
                </div>
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-primary float-end"
                    onClick={handleAdd}
                  >
                    Add FAQ
                  </button>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={FaqData}
                defaultSortFieldId
                pagination={5}
                paginationPerPage={10}
                highlightOnHover
                fixedHeader
                fixedHeaderScrollHeight="500px"
              />
              {/* add */}
              <Modal
                show={show}
                fullscreen
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add FAQ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <div className="mb-3">
                      <label htmlFor="exampleInputText1" className="form-label">
                        Category
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputText1"
                        placeholder="Enter Category "
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputText1" className="form-label">
                        Question
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Answer
                      </label>
                      <JoditEditor
                        ref={editorRef}
                        value={answer}
                        onBlur={(content) => setAnswer(content)}
                      />
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button type="button" className="btn btn-primary">
                    Add
                  </button>
                </Modal.Footer>
              </Modal>

              {/* edit */}
              <Modal
                show={editShow}
                fullscreen
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit FAQ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <div className="mb-3">
                      <label htmlFor="exampleInputText1" className="form-label">
                        Category
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputText1"
                        placeholder="Enter Category "
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputText1" className="form-label">
                        Question
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Answer
                      </label>
                      <JoditEditor
                        ref={editorRef}
                        value={answer}
                        onBlur={(content) => setAnswer(content)}
                      />
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button type="button" className="btn btn-primary">
                    Edit
                  </button>
                </Modal.Footer>
              </Modal>
              {/*Delete Modal */}
              <DeleteModal
                modalTitle={"FAQ"}
                delShow={delShow}
                handleClose={handleClose}
                // handleRemove={handleRemove}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
