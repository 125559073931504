import React from "react";
import ViewProduct from "../components/Product/ViewProduct";

export default function AllProduct() {
  return (
    <div className="page-content">
      <ViewProduct />
    </div>
  );
}
