export const FaqData = [
  {
    Category: "Shipping Information",
    Question: "How will my parcel be delivered?",
    Answer:
      "Lorem ipsum dolor sit amet, consectetur adip id el aspect et non proident et ullamcorper lore",
  },
  {
    Category: "Shipping Information",
    Question: "Do I pay for delivery?",
    Answer:
      "Lorem ipsum dolor sit amet, consectetur adip id el aspect et non proident et ullamcorper lorem Lorem ipsum dolor sit amet, consectetur adip",
  },
  {
    Category: "Shipping Information",
    Question: "Will I be charged for the custom fees?",
    Answer:
      "Lorem ipsum dolor sit amet, consectetur adip id el aspect et non proident et ullamcorper lore",
  },
  {
    Category: "Order and Returns",
    Question: "Tracking my Order",
    Answer:
      "Lorem ipsum dolor sit amet, consectetur adip id el aspect et non proident et ullamcorper lore",
  },
  {
    Category: "Order and Returns",
    Question: "I haven't received my order yet",
    Answer:
      "Lorem ipsum dolor sit amet, consectetur adip id el aspect et non proident et ullamcorper lore",
  },
  {
    Category: "Order and Returns",
    Question: "How can I return an item?",
    Answer:
      "Lorem ipsum dolor sit amet, consectetur adip id el aspect et non proident et ullamcorper lore",
  },
  {
    Category: "Payments",
    Question: "How will my parcel be delivered",
    Answer:
      "Lorem ipsum dolor sit amet, consectetur adip id el aspect et non proident et ullamcorper lore",
  },
  {
    Category: "Payments",
    Question: "Do I pay for delivery?",
    Answer:
      "Lorem ipsum dolor sit amet, consectetur adip id el aspect et non proident et ullamcorper lore",
  },
  {
    Category: "Payments",
    Question: "Will I be charged custom fees?",
    Answer:
      "Lorem ipsum dolor sit amet, consectetur adip id el aspect et non proident et ullamcorper lore",
  },
];
