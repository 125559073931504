import React from "react";
import ProfilePage from "../components/ProfilePage/ProfilePage";

const Profile = () => {
  return (
    <div className="page-content">
      <ProfilePage />
    </div>
  );
};

export default Profile;
