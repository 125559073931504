import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import URL from "../../configuration/config";
import Loading from "../Elements/Loading";

export default function OrderPreview() {
  const [orderDetail, setOrderDetail] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOrderDetail();
  }, []);

  const getOrderDetail = () => {
    setLoading(true);
    URL.get("/orders/all")
      .then((response) => {
        setLoading(false);
        setOrderDetail(response.data.data.orders);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <div className="row">
        {loading ? (
          <Loading />
        ) : (
          <div className="col-lg-12 col-xl-12 stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline mb-2">
                  <h6 className="card-title mb-2">Recent Orders</h6>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover mb-0">
                    <thead>
                      <tr>
                        <th className="pt-0">Order Date</th>
                        <th className="pt-0">Order Reference</th>
                        <th className="pt-0">Order By</th>
                        <th className="pt-0">Status</th>
                        <th className="pt-0">Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetail.map((item, i) => {
                        const timestamp = item.created_at;
                        const date = new Date(timestamp);
                        const dateOnly = date.toISOString().slice(0, 10);
                        return (
                          <tr key={i}>
                            <td>{dateOnly}</td>
                            <td>{item.ref}</td>
                            <td>{item.customer.name}</td>
                            <td>
                              {item.order_status === "order_placed" ? (
                                <span className="badge border border-primary text-primary">
                                  Order placed
                                </span>
                              ) : (
                                <span className="badge border border-danger text-danger">
                                  Order not placed
                                </span>
                              )}
                            </td>
                            <td>Rs {item.grand_total}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>{" "}
      {/* row */}
    </div>
  );
}
